import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, CircularProgress, Container, Drawer, FormControl, Snackbar, Grid, IconButton, TextField, Tooltip, Typography, FormControlLabel, RadioGroup, Radio, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Email, FileCopy } from '@material-ui/icons';
import AddI from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Close';
import { Alert, Autocomplete } from '@material-ui/lab';
import { API_URL, DOMAIN } from '../../Constants';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
import Moment from 'react-moment';
import Fab from '@mui/material/Fab';
import { ViewList } from '@mui/icons-material';

import { Preview, GroupAdd } from '@mui/icons-material';
import ManageQuestionDrawer from './ManageQuestionDrawer';
import { copyTextToClipboard, sendNotificationToUser } from '../../Method.util';
import TestUsers from './testUsers';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  drawer: {
    padding: '10px 15px',
    width: 700
  },
  gridContainer: {
    marginTop: '25px'
  },
  dialogPaper: {
    minHeight: '200px'
  },
  dialogPaperRemoveConf: {
    minHeight: '100px'
  },
  addQuestionIcon: {
    marginRight: 10
  }
});

const questionListHeader = [
  { field: 'id', headerName: 'S.no', hide: true},
  { field: 'question', headerName: 'Questions', headerAlign: 'center', width: 600, renderCell: (cellValues) => {
    return (
      <div
        style={{
          color: "primary",
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        {cellValues.value}
      </div>
    );
  }}
];

class List extends React.Component {

  constructor(props) {
    super(props);
    this.state = {  
      addQuestionDrawer: false,
      testLists:[],
      questionList: [],
      isloader: false,
      selectedTestID: null,
      selectedTestName: null,
      question_level: 0,
      categoryArray: [],
      questionsLevelArray: [],
      category_id: 0,
      isGetQuestionLoader: false,
      viewQuestionID: 0,
      viewQuestionPopup: false,
      viewQuestionPopupLoader: false,
      questionDetailsByID: [],
      isAletDialog: false,
      displayMSG: false,
      errorType: null,
      selectedRows: [],
      selectedQuestionArrayForTest: [],
      openAlert: false,
      manageQuestionDrawer: false,
      isLoaderEmail: false,
      addUserDialog: false
    };
  }

  componentDidMount() {
    this.displayTestList();
  }

  getCategories=(e)=>{
    axios(
      {
        method:"POST",
        url:API_URL+"allcategories",
        data:{status:1},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        this.setState({            
            categoryArray : data.data.data.map(item=>{
                return {
                    title : item.category,
                    id : item.id
                }
            })
        })                                  
      }).catch(error=>console.log(error));
  }

  getQuestionLevel=(e)=>{
    axios({method:"POST",url:API_URL+"allquestionlevel",data:{status:1}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        this.setState({
          questionsLevelArray : data.data.data.map(item=>{
                return {
                    title : item.title,
                    id : item.id
                }
            })
        })                         
      }).catch(error=>console.log(error));
  }

  getQuestions = (e) => {
    const { category_id, question_level, selectedTestID } = this.state;
    if(category_id) {     
      this.setState({
        isGetQuestionLoader: true
      })
      const params = {
        category_id: (category_id) ? category_id.id : 0,
        question_level_id: (question_level) ? question_level.id : 0,
        selectedTestID: selectedTestID
      }
      axios({method:"POST",url:API_URL+"allquestions",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
        .then(data => {
            var questionList = data.data.data.map(item => {
              var question = item.title.replace(/<[^>]+>/g, '');
              return { 
                  id : item.id,
                  question : question
              }
            });
            if(this.state.selectedQuestionArrayForTest.length > 0) {
              const selectedQuestionArrayForTest = this.state.selectedQuestionArrayForTest;
              var questionList  = questionList.filter(function(array_el){
                return selectedQuestionArrayForTest.filter(function(anotherOne_el){
                  return anotherOne_el === array_el.id;
                }).length === 0
              });
            }
            this.setState({
              questionList: questionList,
              selectedRows: [],
              selectedQuestionArrayForTest: []
            });
          this.setState({
            isGetQuestionLoader: false
          })                                
        }).catch(error=>console.log(error));
    } else {
      this.setState({questionList:[]});
    }
  }

  getQuestionsByID = (e) => {
    this.setState({
      viewQuestionPopupLoader: true
    });    
    const params = {
      id: (this.state.viewQuestionID) ? this.state.viewQuestionID : 0
    }
    axios({method:"POST",url:API_URL+"getquestionbyid",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => { 
        const dataArray = data.data.data[0];
        this.setState({
          questionDetailsByID : {
            id: dataArray.id,
            title: dataArray.title,
            option_a: dataArray.option_a,
            option_b: dataArray.option_b,
            option_c: dataArray.option_c,
            option_d: dataArray.option_d,
            answer: dataArray[dataArray.answer]
          },
          viewQuestionPopupLoader: false
        });                               
      }).catch(error=>console.log(error));
  }

  displayTestList = () => {
    this.setState({
      isloader: true
    });
    axios({method:"POST",url:API_URL+"test/list",data:{}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                     
      .then(data => {
        const dataNew = data.data.data.map((item, index) => {
          return {
              id:item.id,
              test_name:item.test_name,
              slug:item.slug,
              status:item.status,
              company_name:item.company_name
          }
        })
        this.setState({
          testLists:dataNew,
          isloader: false
        }, () => {
          
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  renderStatusButton = (e) => {
    return (
          <>
            <Tooltip title="Current Status">
              <Button variant="contained" size="small" onClick={() => this.updateStatus(e.row.id, e.row.status)} style={{minWidth: 90, textAlign: 'center'}} color={e.row.status === 1 ? 'danger' : 'secondary'}>{e.row.status === 1 ? 'Stop' : 'Start Your Test'}</Button>
            </Tooltip>
          </>
    )
  }

  renderCreatedDate = (e) => {
    return (
          <>
            <Moment format="ll">{e.row.created_at}</Moment>
          </>
    )
  }

  renderActionButton = (e) => {
    return (
      <>
        <Tooltip title="Edit Test"><IconButton onClick={() => this.editTest(e)}><EditIcon color="primary" /></IconButton></Tooltip>
        {/* <Tooltip title="Manage Settings"><IconButton><Settings color="primary" /></IconButton></Tooltip> */}   
        <Tooltip title="Copy paper link">
          <IconButton onClick={() => this.copyURL(e)}><FileCopy color="primary" /></IconButton>
        </Tooltip>
        <Tooltip title="Delete Test"><IconButton onClick={() => this.removeTest(e)}><DeleteIcon color="error"/></IconButton></Tooltip>
      </>
    )
  }

  notificationToUser = async (e) => {
    this.setState({
      isLoaderEmail: true,
      selectedTestID: e.id
    })
    const params = {
      testID: e.id,
      slug: e.row.slug,
      token: this.props.userdata.token
    }
    const result = await sendNotificationToUser(params);
    if(result.success) {
      this.setState({
        displayMSG: true,
        message: result.message,
        errorType: 200,
        isLoaderEmail: false
      })
    }
  }

  copyURL = (e) => {
    const url = DOMAIN +'/'+ e.row.slug;
    copyTextToClipboard(url)
    .then(() => {
      this.setState({
        displayMSG: true,
        message: 'URL Copied Successfully!',
        errorType: 200,
      });        
    })
  }

  renderResultButton = (e) => {
    return (
      <>
        <Tooltip title="View Score"><IconButton><Preview color="primary" /></IconButton></Tooltip>
        {this.props.userdata.user_type == 8 && (
          <>
            <Tooltip title="Email to users">
              {
                this.state.isLoaderEmail && this.state.selectedTestID == e.id ? (
                  <IconButton><CircularProgress size={25} /></IconButton>
                ) : (
                  <IconButton onClick={() => this.notificationToUser(e)}><Email color="primary" /></IconButton>
                )
              }           
            </Tooltip>
            <Tooltip title="Add / Remove Users">
              <IconButton onClick={() => this.addUserDialogHandleOpen(e)}><GroupAdd color="primary" /></IconButton>         
            </Tooltip>
          </>
        )}
      </>
    )
  }

  editTest = (e) => {
    this.setState({
      selectedTestID: e.id
    });
    this.props.history.push({ pathname: '/dashboard/test/edit', state: { testid: e.id }});
  }

  removeTest = (e) => {
    this.setState({
      selectedTestID: e.id
    }, () => {
      this.setState({
        isAletDialog: true
      });
    });
  }

  updateStatus = (id, currentStatus) => {
    axios(
      {
        method:"POST",
        url:API_URL+"test/updateStatus",
        data:{status:currentStatus, id},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        this.displayTestList();       
        this.setState({          
          displayMSG: true,
          message: 'You have updated the status successfully.',
          errorType: 200,
        })
      }).catch(error=>console.log(error));
  }

  closeAddQuestiondrawer = () => {
    this.setState({
      addQuestionDrawer: false
    });
  }
  addUserDialogHandleOpen = () => {
    this.setState({
      addUserDialog: true
    });
  }
  addUserDialogHandleClose = () => {
    this.setState({
      addUserDialog: false
    });
  }

  openAddQuestiondrawer = () => {
    this.setState({
      questionList: [],
      selectedQuestionArrayForTest: [],
      selectedRows: [],
      category_id: 0,
      question_level: 0,
      addQuestionDrawer: true,
    });
  }

  handleCellClick = (param, event) => {    
    event.stopPropagation();
  };

  alertDialogForTest = () => {
    this.setState( prevState => ({
      isAletDialog: !prevState.isAletDialog
    }));
  }

  removeTestByID = () => {
    this.setState({
      isloaderForConf: true
    });
    const params = {
      id: this.state.selectedTestID
    }
    axios({method:"POST",url:API_URL+"test/remove",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        this.setState({
          displayMSG: true,
          message: 'You have deleted your test paper successfully.',
          errorType: 200,     
        })
        setTimeout(() => {
          this.displayTestList();
          this.setState({
            isAletDialog: false,
            isloaderForConf: false
          });
        }, 1000)                                           
      }).catch(error=>console.log(error));
  }

  handleCellClickQuestion = (param, event) => {
    if(param.field === 'question') {
      this.setState({
        viewQuestionID: param.id,
        viewQuestionPopup: true
      }, () => {
        this.getQuestionsByID();
      })
    }
    event.stopPropagation();
  };
  
  handleRowClick = (param, event) => {    
    event.stopPropagation();
  };

  categoryHandleChange = (event, value) => {
    this.setState({
      category_id: value
    }, () => {
      this.getQuestions();
    });
  }

  levelHandleChange = (event, value) => {
    this.setState({
      question_level: value
    }, () => {
      this.getQuestions();
    });
  }

  handleCloseQuestionPopup = () => {
    this.setState({
      viewQuestionPopup: false
    });
  }

  PageLink = params => {
    const page = (params === 'list') ? '/dashboard/test/list' : '/dashboard/test/add';
    this.props.history.push(page);
  }
  handleCloseDisplayMSG = () => {
    this.setState({
      displayMSG: false
    });
  }
  addQuestionsToResTest = () => {
    this.setState({
      isGetQuestionLoader: true
    });
    const { selectedRows, selectedTestID } = this.state;
    var questionsArray = [];    
    questionsArray = selectedRows.map(function (row) {
      return row.id;
    });
    this.setState({
      selectedQuestionArrayForTest: questionsArray
    });
    axios(
      {
        method:"POST",
        url:API_URL+"addquestionstotest",
        data:{questionsIDArray: questionsArray, testId: selectedTestID}, 
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {        
        setTimeout(() => {
          this.getQuestions();
          this.setState({
            openAlert: true
          })        
        }, 500)
        setTimeout(() => {
          this.setState({
            openAlert: false
          })        
        }, 5000)                        
      }).catch(error=>console.log(error));
  }

  renderQuestionButton = (e) => {
    return (
          <>
            <Fab onClick={() => this.addQuestionHandleClick(e)} color="primary" aria-label="add" size="small" style={{marginRight: 10}}>
              <Tooltip title="Add Questions"><AddIcon /></Tooltip>
            </Fab>
            <Fab onClick={() => this.viewQuestionHandleClick(e)} aria-label="add" color="secondary" size="small">
              <Tooltip title="Manage Questions"><ViewList /></Tooltip>
            </Fab>
          </>
    )
  }

  addQuestionHandleClick = (param) => {
    this.setState({
      selectedTestID: param.id,
      selectedTestName: param.row.test_name
    }, () => {
      this.openAddQuestiondrawer();
      this.getCategories();
      this.getQuestionLevel();
    });
  }

  viewQuestionHandleClick = (param) => {
    this.setState({
      selectedTestID: param.id,
      selectedTestName: param.row.test_name,
      manageQuestionDrawer: true,
    }, () => {
    });
  }

  handleCloseDrawer = () => {
    this.setState( prevState => ({
      manageQuestionDrawer: !prevState.manageQuestionDrawer,
      selectedTestID: 0
    }))
  }

  render() {  
    let { classes } = this.props;
    let { addUserDialog, isloader, selectedTestName, isloaderForConf, displayMSG, errorType, categoryArray, message, questionsLevelArray, questionDetailsByID, viewQuestionPopupLoader, manageQuestionDrawer, selectedTestID } = this.state;
    return (             
        <Container className={classes.container}>
          {
                displayMSG ?            
                  <Snackbar open={displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                    <MuiAlert
                      onClose={this.handleCloseDisplayMSG}
                      severity={errorType === 200 ? 'success' : 'warning'}
                      elevation={6}
                      variant="filled"
                    >
                      {message}
                    </MuiAlert>                  
                  </Snackbar>
                  :
                  null
              }
          <Card className={classes.root} variant="outlined">          
            <CardContent>          
            <Button color="primary" variant="outlined" onClick={()=>this.PageLink('add')} size="large" startIcon={<AddIcon />} style={{marginBottom: '10px'}}>
              CREATE NEW PAPER
            </Button>
            <Grid container spacing={1}>
              {
                !isloader ?  
                  <Grid item xs={12} sm={12}>      
                    <DataGrid
                      columns = {[
                        { field: 'id', headerName: 'S.no', hide: true, disableColumnMenu: true},
                        { field: 'test_name', headerName: 'Paper Name', width: 350, disableColumnMenu: true },                        
                        { field: 'questions', headerName: 'Questions', width: 120, renderCell: this.renderQuestionButton, disableColumnMenu: true, sortable: false},
                        { field: 'status', headerName: 'Status', width: 150, disableColumnMenu: true, renderCell: this.renderStatusButton},                        
                        { field: 'result', headerName: 'Result', width: 220, renderCell: this.renderResultButton, sortable: false, disableColumnMenu: true},                        
                        { field: 'created_date', headerName: 'Created date', width: 150, disableColumnMenu: true, renderCell: this.renderCreatedDate },
                        { field: 'action', headerName: 'Action', width: 180, renderCell: this.renderActionButton, sortable: false, disableColumnMenu: true}
                      ]}
                      rows={this.state.testLists}
                      autoHeight={true}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      checkboxSelection
                      onCellClick={this.handleCellClick}
                      onRowClick={this.handleRowClick}
                    />                
                  </Grid>
                  :
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={3}>
                    <CircularProgress />
                    </Grid>          
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
              <Drawer
                  anchor='right'
                  open={this.state.addQuestionDrawer}
                  onClose={this.closeAddQuestiondrawer}
                >
                  <form className={classes.drawer} noValidate>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography variant="h6" style={{marginTop: '10px'}} component="div">
                          Add Questions in {this.state.selectedTestName}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} style={{textAlign: 'right'}}><IconButton style={{background: '#efefef'}} onClick={this.closeAddQuestiondrawer}><CancelIcon className={classes.closeButton} /></IconButton></Grid>
                      <Grid item xs={7} className={classes.gridContainer}>
                          <Autocomplete                         
                            id="tags-outlined"
                            options={categoryArray}
                            getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Category"
                                placeholder="Category"
                              />
                            )}
                            onChange={this.categoryHandleChange}
                          />                              
                      </Grid>
                      <Grid item xs={1} className={classes.gridContainer}></Grid>
                      <Grid item xs={4} className={classes.gridContainer}>                        
                        <Autocomplete                         
                            id="tags-outlined"
                            options={questionsLevelArray}
                            getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Level"
                                placeholder="Level"
                              />
                            )}
                            onChange={this.levelHandleChange}
                          />
                      </Grid>
                      {
                        this.state.openAlert && (
                          <Grid item xs={12} className={classes.gridContainer}> 
                            <Alert
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    this.setState({
                                      openAlert: false
                                    });
                                  }}
                                >
                                  <CancelIcon fontSize="inherit" />
                                </IconButton>
                              }
                              sx={{ mb: 2 }}
                            >
                              You have successfully added questions in your Test/Paper
                            </Alert>
                          </Grid>
                        )}
                      {
                        !this.state.isGetQuestionLoader ?                   
                        <Grid item xs={12} className={classes.gridContainer}>
                          <DataGrid
                            rows={this.state.questionList}
                            autoHeight={true}
                            columns={questionListHeader}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={(ids) => {                              
                              const data = this.state.questionList;
                              const selectedIDs = new Set(ids);
                              const selectedRows = data.filter((row) =>
                                selectedIDs.has(row.id),
                              );
                              this.setState({
                                selectedRows: selectedRows
                              });
                            }}
                            onCellClick={this.handleCellClickQuestion}
                            onRowClick={this.handleRowClick}
                          />
                          {
                            this.state.selectedRows.length > 0 ?                          
                            <Button variant="contained" color="primary" startIcon={<AddI />} style={{marginTop: '20px'}} onClick={this.addQuestionsToResTest}>
                              Add ({this.state.selectedRows.length}) question
                            </Button>
                            :
                            null
                          }            
                        </Grid>                        
                        :
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{marginTop: '50px'}}
                        >
                          <Grid item xs={3}>
                            <CircularProgress />
                          </Grid>          
                        </Grid>
                      }
                    </Grid>
                  </form>

                </Drawer>
                <Dialog open={this.state.viewQuestionPopup} onClose={this.handleCloseQuestionPopup} fullWidth maxWidth="sm" classes={{ paper : classes.dialogPaper}}>
                  {
                    !viewQuestionPopupLoader ?
                      <>
                      <DialogContent>
                        <DialogContentText>
                          {ReactHtmlParser(questionDetailsByID.title)}

                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="gender"
                              defaultValue={questionDetailsByID.answer}
                              name="radio-buttons-group"
                            >
                              <FormControlLabel value={questionDetailsByID.option_a} control={<Radio />} label={questionDetailsByID.option_a} />
                              <FormControlLabel value={questionDetailsByID.option_b} control={<Radio />} label={questionDetailsByID.option_b} />
                              <FormControlLabel value={questionDetailsByID.option_c} control={<Radio />} label={questionDetailsByID.option_c} />
                              <FormControlLabel value={questionDetailsByID.option_d} control={<Radio />} label={questionDetailsByID.option_d} />
                            </RadioGroup>
                          </FormControl>

                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>                     
                        <Button variant="contained" color="secondary" onClick={this.handleCloseQuestionPopup}>Close</Button>
                      </DialogActions>
                      </>
                      :
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{marginTop: '100px'}}
                        >
                        <Grid item xs={3}>
                          <CircularProgress />
                        </Grid>          
                      </Grid>
                  }
                </Dialog>
                <Dialog
                  open={this.state.isAletDialog}
                  onClose={this.alertDialogForTest}
                  fullWidth
                  maxWidth="sm"
                  classes={{ paper : classes.dialogPaperRemoveConf}}
                >
                  {
                    !isloaderForConf ?
                    <>
                    <DialogContent>
                      <DialogContentText>
                        <b>Please note that:-</b> Once you have delete this test paper then this want show any more.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button variant="contained" color="primary" onClick={this.alertDialogForTest} size="small">Cancel</Button>                    
                      <Button variant="contained" color="secondary" onClick={this.removeTestByID} size="small">OK</Button>
                    </DialogActions>
                    </>
                    :
                    <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{marginTop: '30px'}}
                        >
                          <Grid item xs={3}>
                            <CircularProgress />
                          </Grid>          
                        </Grid>
                  }
                </Dialog>
                {
                  manageQuestionDrawer &&  (                
                    <ManageQuestionDrawer
                      open={manageQuestionDrawer}
                      handleCloseDrawer={this.handleCloseDrawer}
                      userdata={this.props.userdata}
                      selectedTestID={selectedTestID}
                      selectedTestName={selectedTestName}
                    />
                )}
                {
                  addUserDialog && (
                    <TestUsers open={addUserDialog} handleClose={this.addUserDialogHandleClose} token={this.props.userdata.token} />
                  )
                }                
        </Container>
        
    );
  }
}
export default withStyles(styles)(withRouter(List));