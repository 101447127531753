import React, { Component } from 'react';
import Banner from '../Banner/Banner';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Pricing from '../Pricing/Pricing';
import QuestionSection from '../QuestionSection/QuestionSection';
import StepSection from '../StepSection/StepSection';
import TestSection from '../TestSection/TestSection';

class Homepage extends Component{
    render(){
        return (
            <div>
                <Navbar />
                <Banner />
                <TestSection title='Online Assessments' description='Looking to prepare for a specific technology? Below tests are perfect if you want to prepare for for an IT Sector, Banking, Railways, Schooling, etc.
Choose your free test series from the given list.' container='h2' isHome={true} />
                <StepSection />
                <Pricing />                
                {/* <InterviewerList />
                <BlogSection /> */}
                <QuestionSection />
                <Footer />
            </div>
        )
    }
}

export default Homepage;