import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../Dashboard/Common/Header';
import ListQuestions from '../Dashboard/Questions/list';
import AddQuestion from '../Dashboard/Questions/add';
import { withStyles } from '@material-ui/core/styles';
import AddCategory from './Category/Add';
import ListCategory from './Category/list';
import AddTest from './Test/Add';
import EditTest from './Test/Edit';
import EditQuestion from './Questions/edit';
import ListTest from './Test/list';
import Home from './Home';
import Profile from './Profile';
import {connect} from 'react-redux';
import UsersList from './User/List';
import AddUser from './User/Add';
import EditUser from './User/Edit';
import GalleryList from './Gallery/List';
import AddGallery from './Gallery/Add';
import Score from './Scores/List';
import EditProfile from './EditProfile';
import Package from './Package';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  }   
});

class Dashboard extends React.Component {
    render() { 
      let pageType = this.props.match.params.page_type;
      let param1 = this.props.match.params.param1;
      let param2 = this.props.location.state && this.props.location.state.testid;
      let { classes, userdata } = this.props;
      if(!userdata.token) {
        userdata = JSON.parse(localStorage.getItem('userData'));
      }
      return <div className={classes.root}>
        <CssBaseline />
        <Header userdata={userdata}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {
                (
                    ()=> {
                        switch(pageType){                            
                            case 'index':
                                  return <Home userdata={userdata}/>         
                            case 'users':
                              if(param1 === 'add'){
                                return <AddUser userdata={userdata} />;
                              } else if(param1 === 'list') {
                                return <UsersList userdata={userdata}/>;
                              } else if(param1 === 'edit') {
                                return <EditUser userdata={userdata} id={param2} />;
                              }
                            case 'question':
                              if(param1 === 'add'){
                                return <AddQuestion userdata={userdata}/>;
                              } else if(param1 === 'list') {
                                return <ListQuestions userdata={userdata}/>;
                              } else if(param1 === 'edit') {
                                return <EditQuestion id={param2} userdata={userdata}/>;
                              }                               
                            case 'category':
                                if(param1 === 'add'){
                                  return <AddCategory userdata={userdata}/>;
                                } else if(param1 === 'list') {
                                  return <ListCategory userdata={userdata}/>;
                                }
                            case 'test':
                                if(param1 === 'add'){
                                  return <AddTest userdata={userdata}/>;
                                } else if(param1 === 'list') {
                                  return <ListTest userdata={userdata}/>;
                                } else if(param1 === 'edit') {
                                  return <EditTest id={param2} userdata={userdata}/>;
                                }
                            case 'scores':
                              if(param1) {
                                return <Score userdata={userdata} slug={param1}/>;
                              }
                            case 'profile':
                              if(param1 === 'edit'){                             
                                return <EditProfile userdata={userdata} />;
                              } else {
                                return <Profile userdata={userdata} />;
                              }
                            case 'package':
                              return <Package userdata={userdata} />;                    
                            case 'gallery':
                              if(param1 === 'add'){
                                return <AddGallery userdata={userdata}/>;
                              } else if(param1 === 'list') {
                                return <GalleryList userdata={userdata}/>;
                              }                              
                            default:
                              return null;
                                
                        }
                    }
                )()
            } 
            </main>        
    </div>
    }
}

const mapStateToProps = (state) => {
  return {
    userdata:state.User.user
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));