import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Grid, LinearProgress, Snackbar } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { API_URL, API_BASE_URL } from '../../Constants';
import axios from 'axios';
import Moment from 'react-moment';
import AddIcon from '@material-ui/icons/AddCircle';
import { withRouter } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  titleHeader: {
    paddingLeft: 10
  },
  copyIcon: {
    cursor: 'pointer'
  }
});
const tableRef = React.createRef();

const actions = [
  {
    icon: 'refresh',
    tooltip: 'Refresh Data',
    isFreeAction: true,
    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
  },
  {
    tooltip: 'Remove All Selected Users',
    icon: () => <DeleteIcon />,
    onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
  },
  {
    icon: () => <EditIcon color="primary" />,
    tooltip: 'Edit',
    onClick: (event, rowData) => {},
    position: "row"
  },
  {
    icon: () => <DeleteIcon color="error" />,
    tooltip: 'Delete',
    onClick: (event, rowData) => {},
    position: "row"
  }
];

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      userLists: [],
      displayMSG: false,
      errorType: '',
      message: '',
      isCopied: false
    }
  }

  componentDidMount() {
    this.getGallery();
  }

  getGallery = (e) => {
    this.setState({
      loader: true
    })
    axios(
      {
        method:"POST",
        url:API_URL+"gallery/list",
        data:{status:1},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        var users = data.data.data;
        this.setState({
          userLists : users.map(item => {
              return {
                id : item.id,
                image : <img height="50" src={API_BASE_URL+'gallery/papers_logo/'+item.image} />,
                copy_url : <ContentCopyIcon style={{cursor: 'pointer'}} onClick={() => this.handleCopyClick(API_BASE_URL+'gallery/papers_logo/'+item.image)} />,
                status : item.status ? <Button variant="outlined" color="info" size="small">Active</Button> : <Button variant="outlined" color="secondary" size="small">Deactive</Button>,
                date : <Moment format="LL">{item.created_at}</Moment>
              }
          }),
          loader: false
        })
      }).catch(error=>console.log(error));
  }

  copyTextToClipboard = async(text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  handleCopyClick = (url) => {
    this.copyTextToClipboard(url)
      .then(() => {
        this.setState({
          isCopied: true
        }, () => {
          setTimeout(() => {
            this.setState({
              isCopied: false
            })
          }, 5000);
        });        
      })
      .catch((err) => {
        console.log(err);
      });
  }

  PageLink = () => this.props.history.push('/dashboard/gallery/add');

  // updateStatus = (id, currentStatus) => {
  //   axios(
  //     {
  //       method:"POST",
  //       url:API_URL+"companyusers/updateStatus",
  //       data:{status:currentStatus, id},
  //       headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
  //     }
  //     ).then(data => {
  //       this.getUsers();       
  //       this.setState({          
  //         displayMSG: true,
  //         message: 'You have updated the status successfully.',
  //         errorType: 200,
  //       })
  //     }).catch(error=>console.log(error));
  // }

  handleCloseDisplayMSG = () => {
    this.setState(prevState =>({
      displayMSG: !prevState.displayMSG
    }))
  }
  
  render() {
    let { classes } = this.props;
    let { loader, userLists, displayMSG, message, errorType } = this.state;
    return (       
        <Container className={classes.container}>
          {
            displayMSG ?            
              <Snackbar open={displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                <MuiAlert
                  onClose={this.handleCloseDisplayMSG}
                  severity={errorType === '200' ? 'success' : 'warning'}
                  elevation={6}
                  variant="filled"
                >
                  {message}
                </MuiAlert>                  
              </Snackbar>
              :
              null
          }
          <Card className={classes.root} variant="outlined">
          {
            this.state.copySuccess ?
            <div style={{"color": "green"}}>
              Success!
            </div> : null
          }
          <CardContent>       
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                {
                  !loader ? (
                    <MaterialTable
                      title= {<Button color="primary" variant="outlined" size="large" startIcon={<AddIcon />} onClick={()=>this.PageLink()}>
                                Upload File
                              </Button>}
                      columns={[
                        { title: 'Image', field: 'image' },                        
                        { title: 'Copy URL', field: 'copy_url' },
                        { title: 'Status', field: 'status' },
                        { title: 'Created Date', field: 'date' }
                      ]}
                      data={userLists}
                      options={{
                        draggable: false,
                        search: true,
                        sorting: true,
                        pageSize: 5,
                        paginationType: 'stepped',
                        showTitle: true,
                        toolbar: true,
                        maxBodyHeight: 500,
                        actionsColumnIndex: -1,
                        selection: true,
                      }}
                      actions={actions}
                      components={{
                        Container: props => props.children,
                        Toolbar: props => (
                          <MTableToolbar {...props} classes={{ root: classes.titleHeader }} />
                        )
                      }}
                    />
                  ) : (
                      <LinearProgress />
                  )}
              </Grid>
            </Grid>
            </CardContent>
          </Card>
        </Container>  
    );
  }
}
export default withStyles(styles)(withRouter(Users));