import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, Paper, Button, Typography, Avatar } from '@material-ui/core';
import { Email, Phone, Edit } from '@material-ui/icons';

import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
  },
  seprateBox: {
    textAlign: 'center',
    padding: 20,
    width: 500
  },
  spacing: {
    padding: '20px 0px'
  },
  PhoneIcon: {
    verticalAlign: 'middle'
  }
});

class Profile extends React.Component {
  goToPage = (path) => {
    this.props.history.push(path);
  }
  render() {
    let { classes, userdata } = this.props;
      return (                  
          <Container className={classes.container}>
            <Typography variant="h5" component="div" className={classes.spacing}>
              Manage Profile
            </Typography>
            <Card style={{paddingTop: 5, paddingLeft: 5}} variant="outlined">
              <CardContent>            
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >                  
                  <Paper elevation={3} className={classes.seprateBox}>                    
                      <Avatar
                        alt={userdata.name}
                        src="/static/images/avatar/1.jpg"
                        style={{width: 84, height: 84, display: 'inline-flex'}}
                      />
                      <Typography variant="h5" component="div" className={classes.spacing}>
                        {userdata.name}
                      </Typography>
                      <Typography>
                      <Email className={classes.PhoneIcon} /> {userdata.email}
                      </Typography>
                      <Typography>
                        <Phone className={classes.PhoneIcon} /> {userdata.phone}
                      </Typography>
                      
                      <Button style={{margin: 15}} onClick={() => this.goToPage('/dashboard/profile/edit')} variant="outlined" startIcon={<Edit />}>
                        Edit Profile
                      </Button>
                      <Typography style={{padding: 10}}>
                      In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                      </Typography>        
                  </Paper>
                </Grid>
              </CardContent>
            </Card>
          </Container>  
      );
    }
}
export default withStyles(styles)(withRouter(Profile));