import React, { Component } from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";

class Meta extends Component  {
    render(){
        const { meta_title, meta_description, url } = this.props;
        return (
                <Helmet>
                    <title>{meta_title}</title>
                    <meta name="description" content={meta_description} data-rh="true"/>
                    <link rel="canonical" href={url} />
                </Helmet>
        )
    }
}
export default Meta;