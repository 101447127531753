import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { Receipt, GroupAdd, Person } from '@material-ui/icons';
import Fab from '@mui/material/Fab';
import Payments from '@mui/icons-material/Payments';

import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
  },
  seprateBox: {
    cursor: 'pointer',
    textAlign: 'center'
  }
});

class Home extends React.Component {

  goToPage = (path) => {
    this.props.history.push(path);
  }

  render() {
    let { classes, userdata } = this.props;
      return (                  
          <Container className={classes.container}>
            <Typography variant="h5" component="div" style={{padding: '20px 0px'}}>
              Hi, Welcome back
            </Typography>
            <Card style={{paddingTop: 5, paddingLeft: 5}} variant="outlined">
              <CardContent>            
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Paper className={classes.seprateBox} elevation={3} onClick={() => this.goToPage('/dashboard/test/list')}>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Fab aria-label="add" color="primary" size="small">
                              <Tooltip title="Manage Papers"><Receipt /></Tooltip>
                            </Fab>
                            <Typography sx={{ mb: 1 }} color="text.secondary" style={{padding: '10px 0px'}}>
                              Test Papers
                            </Typography>                   
                          </CardContent>      
                        </Card>
                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <Paper elevation={3} className={classes.seprateBox} onClick={() => this.goToPage('/dashboard/users/list')}>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Fab aria-label="add" color="secondary" size="small">
                              <Tooltip title="Manage Users"><GroupAdd /></Tooltip>
                            </Fab>
                            <Typography sx={{ mb: 1 }} color="text.secondary" style={{padding: '10px 0px'}}>
                              Manage Users
                            </Typography>                   
                          </CardContent>      
                        </Card>
                      </Paper>
                    </Grid>                    
                    <Grid item xs={3}>
                      <Paper elevation={3} className={classes.seprateBox} onClick={() => this.goToPage('/dashboard/profile')}>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Fab aria-label="add" color="secondary" size="small">
                              <Tooltip title="Manage Users"><Person /></Tooltip>
                            </Fab>
                            <Typography sx={{ mb: 1 }} color="text.secondary" style={{padding: '10px 0px'}}>
                              Manage Profile
                            </Typography>                   
                          </CardContent>      
                        </Card>
                      </Paper>
                    </Grid>
                    <Grid item xs={3}>
                      <Paper elevation={3} className={classes.seprateBox} onClick={() => this.goToPage('/dashboard/package')}>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Fab aria-label="add" color="primary" size="small">
                              <Tooltip title="Package"><Payments /></Tooltip>
                            </Fab>
                            <Typography sx={{ mb: 1 }} color="text.secondary" style={{padding: '10px 0px'}}>
                              Package
                            </Typography>                   
                          </CardContent>      
                        </Card>
                      </Paper>
                    </Grid>
                    {/* <Grid container item style={{marginTop: 15}}>
                      <Grid item xs={6}>
                        <Paper elevation={3} style={{padding: 10}}>
                        <Grid container>
                          <Typography color="text.secondary" variant="h6" component="div" style={{padding: '10px 0px 20px 0px'}}>
                            Recently Created Test Papers
                          </Typography>  
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={2}><img width="50" src="https://minimal-kit-react.vercel.app/static/mock-images/covers/cover_1.jpg" /></Grid>
                          <Grid item xs={7}>
                            <Typography style={{fontSize: 16}}>React.js Online Test</Typography>
                          </Grid>
                          <Grid item xs={3}>3rd Feb 2022</Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={2}><img width="50" src="https://minimal-kit-react.vercel.app/static/mock-images/covers/cover_1.jpg" /></Grid>
                          <Grid item xs={7}>
                            <Typography style={{fontSize: 16}}>Advanced React Online Test</Typography>
                          </Grid>
                          <Grid item xs={3}>3rd Feb 2022</Grid>
                        </Grid>                      
                        </Paper>
                      </Grid>
                    </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Container>  
      );
    }
}
export default withStyles(styles)(withRouter(Home));