import React from 'react';
import axios from 'axios';
import AddIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import { withStyles, TextField, Container, Grid, Card, Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar } from '@material-ui/core';
import { API_URL } from '../../Constants';
import MuiAlert from "@material-ui/lab/Alert";
import { withRouter } from 'react-router-dom';
import { InputLabel, MenuItem, FormControl, Select, FormGroup, FormControlLabel, Switch } from '@mui/material';

const styles = (theme) => ({  
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  formControl:{
    width:"100%"
  },
  addUserButton: {
    marginTop: '5px !important'
  }
});

class Add extends React.Component {

  constructor(props) {
    super(props);
    this.state = {     
      category_id: 0,
      categoryArray: [],
      category: null,
      visibility: 'Public',
      open: false,
      showAddUserSection: false,
      userLists: [],
      selectedUsers: [],
      messgage: '',
      errorType: null,
      displayMSG: false,
      username: null,
      useremail: null,
      rating: null,
      logo: null,
      description: '',
      meta_title: '',
      meta_description: '',      
      selectedFile: null,
      display_title: '',
      is_popular: false,
      disabledSelect: false,
      marks: 0,
      time: 0
    };
  }

  componentDidMount() {
    if(this.props.userdata.user_type == 8) {
      this.setState({
        visibility: 'Private',
        showAddUserSection: true,
        disabledSelect: true
      }, () => {
        this.getCompanyUsers();
      });
    }
  }

  getCompanyUsers = (e) => {
    axios(
      {
        method:"POST",
        url:API_URL+"getcompanyusers",
        data:{status:1},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        const { selectedUsers } = this.state;
        var users = data.data.data;        
        if(selectedUsers.length > 0) {
            users = users.filter(function(array_el){
              return selectedUsers.filter(function(anotherOne_el){
                  return anotherOne_el.id === array_el.id;
              }).length === 0
            });
        }

        this.setState({
          userLists : users.map(item=>{
              return {
                id : item.id,
                name : item.name,
                email : item.email
              }
          })
        })                                  
      }).catch(error=>console.log(error));
  }

  handleSubmit = (e) => {
    const { title, visibility, selectedUsers, rating, description, logo, meta_title, meta_description, display_title, is_popular, time, marks } = this.state;
    var fd = new FormData();
    if(this.state.selectedFile) {
      fd.append("logo", this.state.selectedFile, this.state.selectedFile.name);
    }
    fd.append('title', title);
    fd.append('display_title', display_title);
    fd.append('is_popular', is_popular);
    fd.append('visibility', visibility);
    fd.append('users_list', JSON.stringify(selectedUsers));
    fd.append('rating', rating);
    fd.append('description', description);
    fd.append('meta_title', meta_title);
    fd.append('meta_description', meta_description);
    fd.append('time', time);
    fd.append('marks', marks);
    this.setState({
      message: '',
      errorType: null
    });
    axios(
      {
        method:"POST",
        url:API_URL+"test/create",
        data:fd, 
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`, 'content-type': 'multipart/form-data'}
      }
      ).then(data => {         
            this.setState({
              message: 'You have successfully created your Paper.',
              errorType: 200,
              displayMSG: true,
              title: '',
              visibility: ''
            });
            setTimeout(() => {
              this.props.history.push('/dashboard/test/list');
            }, 1000)
        })
        .catch(error => {
          this.setState({
            message: error.response.data.message,
            errorType: error.response.data.statusCode,
            displayMSG: true,
            title: ''
          });
        });
  }

  
  addUser = (e) => {
    const params = {
      username: this.state.username,
      useremail: this.state.useremail,
      userstatus: true
    }
    axios(
      {
        method:"POST",
        url:API_URL+"addcompanyusers",
        data:params,
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
            this.setState({
              message: 'You have successfully added your user',
              errorType: 200,
              displayMSG: true,
              username: '',
              useremail: ''
            }, () => {
              this.getCompanyUsers();
              this.handleClose();
            });
        })
        .catch(error => {         
          this.setState({
            message: error.response.data.message,
            errorType: error.response.data.statusCode,
            displayMSG: true,
            username: '',
            useremail: ''
          });
        });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  handleVisibility = (e) => {
    this.setState({
      visibility: e.target.value,
      showAddUserSection: e.target.value === 'Private' ? true : false
    }, () => {
      this.getCompanyUsers();
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  }

  handleCloseDisplayMSG = () => {
    this.setState({
      displayMSG: false
    });
  }  
  
  handleClickOpen = () => {
    this.setState({
      open: true
    });
  }

  getSelectedUsersList = (event, value) => {
    this.setState({
      selectedUsers: value
    });
  }
  PageLink = params => {    
    const page = (params === 'list') ? '/dashboard/test/list' : '/dashboard/test/add';
    this.props.history.push(page);
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });    
  };

  handleToggle = () => {
    this.setState({ is_popular: !this.state.is_popular });
  };

  render() {
    
    let { classes, userdata } = this.props;
    let { title, userLists, username, useremail, disabledSelect } = this.state;
    return (
          <Container className={classes.container}>
              {
                this.state.displayMSG ?            
                  <Snackbar open={this.state.displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                    <MuiAlert
                      onClose={this.handleCloseDisplayMSG}
                      severity={this.state.errorType === 200 ? 'success' : 'warning'}
                      elevation={6}
                      variant="filled"
                    >
                      {this.state.message}
                    </MuiAlert>                  
                  </Snackbar>
                  :
                  null
              }
            <Card variant="outlined">
              <CardContent>
              <Button color="primary" variant="outlined" onClick={()=>this.PageLink('list')} size="large" startIcon={<AddIcon />} style={{marginBottom: '10px'}}>
                ALL PAPERS
              </Button>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>                 
                        <TextField
                          name="title"
                          variant="outlined"
                          fullWidth
                          label="Test Name"                          
                          value={this.state.title}
                          onChange={this.handleChange}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select                            
                              value={this.state.visibility}
                              name="visibility"
                              label="Visibility"
                              variant="outlined"
                              onChange={this.handleVisibility}
                              disabled={disabledSelect}
                            >
                              <MenuItem value={`Public`}>Public</MenuItem>
                              <MenuItem value={`Private`}>For selected users</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>                     
                      {
                        this.state.showAddUserSection ?
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={userLists}
                            getOptionLabel={(option) => option.email}                            
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Users"
                                placeholder="Select user..."
                              />
                            )}
                            onChange={this.getSelectedUsersList}
                          />
                          <Button style={{marginTop: 5}} variant="outlined" color="primary" size="small" className="addUserButton" onClick={this.handleClickOpen}>Add new user</Button>
                          <Dialog open={this.state.open} onClose={this.handleClose}>
                            <DialogContent>
                              <DialogContentText>To access this test, please enter your users detail like name and email here.</DialogContentText>
                              <TextField
                                margin="dense"
                                id="username"
                                name="username"
                                value={username}
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={this.handleChange}
                              />
                              <TextField                              
                                margin="dense"
                                id="useremail"
                                name="useremail"
                                value={useremail}
                                label="Email"
                                type="email"
                                fullWidth
                                variant="outlined"
                                onChange={this.handleChange}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button variant="contained" color="primary" onClick={this.addUser} disabled={!username || !useremail}>Add User</Button>
                              <Button variant="contained" color="secondary" onClick={this.handleClose} style={{marginRight: '16px'}}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        :
                        null
                      }                      
                      
                      { userdata.user_type === 1 && (
                        <>
                        <Grid item xs={6}>             
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          size="large"
                          color='primary'
                          onChange={this.onFileChange}
                        >                         
                          {this.state.selectedFile ? this.state.selectedFile.name : ' Upload Logo'}             
                          
                          <input
                            id="img"
                            hidden
                            name="img"
                            type="file"
                            accept="image/*"
                            onChange={this.onFileChange}
                          />
                        </Button>               
                      </Grid>
                      <Grid item xs={12}></Grid>
                        <Grid item xs={6}>             
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                            <Select                            
                              value={this.state.rating}
                              name="rating"
                              label="Rating"
                              variant="outlined"
                              onChange={this.handleChange}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={1.5}>1.5</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={2.5}>2.5</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={3.5}>3.5</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={4.5}>4.5</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                            </Select>
                          </FormControl>              
                        </Grid>
                        <Grid item xs={6}>                 
                          <TextField
                            name="display_title"
                            variant="outlined"
                            fullWidth
                            label="Display Title"                          
                            value={this.state.display_title}
                            onChange={this.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>                 
                          <TextField
                            name="marks"
                            variant="outlined"
                            fullWidth
                            label="Marks"                          
                            value={this.state.marks}
                            onChange={this.handleChange}
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={6}>                 
                          <TextField
                            name="time"
                            variant="outlined"
                            fullWidth
                            label="Time"                          
                            value={this.state.time}
                            onChange={this.handleChange}
                            autoFocus
                          />
                        </Grid>
                        </>
                      )}
                                         
                      <Grid item xs={12} sm={12}>                 
                        <TextField
                          name="description"
                          variant="outlined"
                          fullWidth
                          label="Test Description"
                          multiline
                          rows={4}
                          value={this.state.description}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      { userdata.user_type === 1 && (
                        <>
                          <Grid item xs={6} sm={6}>                 
                            <TextField
                              name="meta_title"
                              variant="outlined"
                              fullWidth
                              label="Meta Title"
                              multiline
                              rows={4}
                              value={this.state.meta_title}
                              onChange={this.handleChange}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6}>                 
                            <TextField
                              name="meta_description"
                              variant="outlined"
                              fullWidth
                              label="Meta Description"
                              multiline
                              rows={4}
                              value={this.state.meta_description}
                              onChange={this.handleChange}
                            />
                          </Grid>
                          <Grid item xs={2}>                 
                            <FormGroup>
                              <FormControlLabel control={<Switch checked={this.state.is_popular} value={this.state.is_popular} onClick={this.handleToggle} />} label="Is Popular" />
                            </FormGroup>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12}>
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={this.handleSubmit} disabled={!title}>Create Test</Button>
                        <Button variant="contained" color="secondary" disabled={!title}>Reset</Button>
                      </Grid> 
                  </Grid>
                </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(withRouter(Add));