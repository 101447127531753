import { Button, Container, Divider, Grid, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Colors } from '../../Common/colors';

const styles = theme => ({
    flex : {
        display  : 'flex',
        alignItems : 'center',
        justifyContent : 'space-between',
    },
    dflex : {
        display  : 'flex',
        alignItems : 'center',
        justifyContent : 'space-between',
        margin : "7px 0",
        borderBottom: '1px solid #efefef'
    },
    pricingWrapper : {
        minHeight : 300,
        boxShadow : '0px 0px 10px #ccc',
        borderRadius : 10,
        background : '#fff'
    },
    pricingActivewrapper : {
        minHeight : 300,
        boxShadow : '0px 0px 30px #000',
        borderRadius : 10,
        background : '#fff',
        marginTop : 60
    },
    priceSection : {
        minHeight : 100,
        textAlign : 'center',
        padding : '30px 0',
        borderBottom : '1px solid #eaeaea',
        position : 'relative'
    },
    activeCurrencyWrapper : {
        position : 'absolute',
        background : Colors.PRIMARY,
        color : '#fff',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius : 10,
        padding : 20,
        width : '65%'
    },
    bannerWrapper : {
        background: 'linear-gradient(to right, rgb(220,240,252, 90%), rgb(220,240,252, 15%))',
        padding : 50,
        "@media (max-width: 767px)": {
            padding: '25px 0px', 
        }
    },
    listSection : {
        padding : "0px 20px"
    },
    subscribeSection : {
        padding : 20
    },
    outlinedSubscribeButton : {
        fontSize : 20,
        color : Colors.PRIMARY,
        border : '1px solid ' + Colors.PRIMARY
    },
    subscribeButton : {
        background : Colors.PRIMARY,
        color : "#fff",
        fontSize : 20,
        "&:hover" : {
            background : 'rgb(5, 100, 100)',
        }
    },
    m30: {
        margin : 30
    },
    headingWrapper : {
        textAlign : 'center',
        marginBottom : 30
    },
    nameText : {
        fontSize : 15
    },
    subName : {
        color : '#999',
        fontSize : 14
    }
});

const planArray = [
    {
        id : 'free',
        name : 'Free',
        price : '$0',
        features : [
            {
                id : '1',
                name : 'Tests Available',
                quantity : 1
            },
            {
                id : '5',
                name : 'Add Users',
                quantity : 1
            },
            {
                id : '2',
                name : 'Quality Questions',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '3',
                name : 'Predefined Templates Available',
                quantity : <CancelIcon color="error"/>
            },
            {
                id : '4',
                name : 'Feedback Notifications',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '6',
                name : 'Set Time Limit',
                quantity : <CancelIcon color="error"/>
            },
            {
                id : '7',
                name : 'Tests Review',
                quantity : <CancelIcon color="error"/>
            }
        ]
    },
    {
        id : 'enterprise',
        name : 'Enterprise',
        price : '$300',
        features : [
            {
                id : '1',
                name : 'Tests Available',
                quantity : 50
            },
            {
                id : '5',
                name : 'Add Users',
                quantity : 100
            },
            {
                id : '2',
                name : 'Quality Questions',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '3',
                name : 'Predefined Templates Available',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '4',
                name : 'Feedback Notifications',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '6',
                name : 'Set Time Limit',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '7',
                name : 'Tests Review',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '8',
                name : '24 X 7 Help',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '8',
                name : 'Customized Test Templates',
                quantity : <CheckCircleOutlineIcon color="success"/>
            }
        ]
    },
    ,
    {
        id : 'standard',
        name : 'Standard',
        price : '$100',
        features : [
            {
                id : '1',
                name : 'Tests Available',
                quantity : 20
            },
            {
                id : '5',
                name : 'Add Users',
                quantity : 10
            },
            {
                id : '2',
                name : 'Quality Questions',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '3',
                name : 'Predefined Templates Available',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '4',
                name : 'Feedback Notifications',
                quantity : <CheckCircleOutlineIcon color="success"/>
            },
            {
                id : '6',
                name : 'Set Time Limit',
                quantity : <CancelIcon color="error"/>
            },
            {
                id : '7',
                name : 'Tests Review',
                quantity : <CheckCircleOutlineIcon color="success"/>
            }
        ]
    }
]

class Pricing extends Component{
    render(){
        const { classes } = this.props;
        return (
            <div className={classes.bannerWrapper}>
                <Container>
                    <div className={classes.headingWrapper}>
                        <div>
                            <Typography
                                variant="h4"
                                className={classes.heading}
                                style={{paddingBottom: 10}}
                            >
                                Choose your Assessment Plan
                            </Typography>
                            <Typography>
                            Get access to predefined templates which can be customizable, more than 20 test sections based upon different technologies, access to add more than 20 numbers of users, review of given tests, 24*7 help, time limitation, and much more. Browse through these plans and select one according to your need.
                            </Typography>
                        </div>
                    </div>
                    <Grid container spacing={2} style={{alignItems : 'center'}}>
                        {
                            planArray.map((item, index)=>{
                                return (
                                    <Grid item md={4} sm={12} xs={12} key={item.price}>
                                        <div className={index === 1 ? classes. pricingActivewrapper : classes.pricingWrapper}>
                                            <div className={classes.priceSection}>
                                                <div className={index === 1 ? classes.activeCurrencyWrapper : classes.currencyWrapper}>
                                                    <Typography variant="h4">
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="h3">
                                                        {item.price}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={classes.listSection}>
                                            {
                                                item.features.map(feature=> {
                                                    return (
                                                        <>
                                                            <div className={classes.dflex} key={feature.id}>
                                                                <div className={classes.flex}>
                                                                    <KeyboardArrowRightIcon
                                                                        color="default"
                                                                        style={{marginRight : 10}}
                                                                    />
                                                                    <Typography className={classes.nameText}>{feature.name}</Typography>
                                                                </div>
                                                                <Typography>{feature.quantity}</Typography>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            </div>
                                            <div className={classes.subscribeSection}>
                                                {/* <Button
                                                    fullWidth
                                                    className={index !== 1 ? classes.outlinedSubscribeButton : classes.subscribeButton}
                                                    >
                                                    Select Package
                                                </Button> */}
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Container>
            </div>
        )
    }
}
export default withStyles(styles)(Pricing);