import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, TextField, Grid, Card, CardContent, Button, Typography, CircularProgress } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';
import { addCompanyUser } from '../../Method.util';
import DisplayMSGComp from '../Common/DisplayMSG';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  }
});

class Add extends React.Component {

  constructor(props) {
    super(props);
    this.state = {     
      name: '',
      email: '',
      displayMSG: false,
      message: '',
      errorType: null
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

    
  handleSubmit = async () => {
    this.setState({
      isLoader: true
    });
    let { name, email } = this.state;
    const params = {
      username: name,
      useremail: email,
      userstatus: true,
      token: this.props.userdata.token
    }

    const result = await addCompanyUser(params);
    if(result.success) {
      this.setState({
        displayMSG: true,
        message: result.message,
        errorType: 200,
        isLoaderEmail: false,
        isLoader: false,
        username: '',
        useremail: ''
      });
      setTimeout(() => {
        this.props.history.push('dashboard/users/list');    
      }, 1500) 
    }
  }

  handleClose = () => {
    this.setState( prevState => ({
      displayMSG: !prevState.displayMSG
    }))
  }

  render() {
    let { classes } = this.props;
    let { name, email, displayMSG, message, errorType, isLoader } = this.state;
    
    return (
          <Container className={classes.container}>
            <Card variant="outlined">
              <CardContent>
                <DisplayMSGComp
                  display={displayMSG}
                  message={message}
                  errorType={errorType}
                  handleCloseDisplayMSG={this.handleClose}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>  
                    <Typography gutterBottom variant="h6" component="div">
                      Add User
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} container justify="flex-end"> 
                    <Button variant="contained" size="small" startIcon={<ImportExport />} color="primary">
                      Import Users
                    </Button>
                  </Grid>
                </Grid>              
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>                    
                      <Grid item xs={6} sm={6}>               
                        <TextField
                          name="name"
                          variant="outlined"
                          fullWidth
                          label="Name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>     
                        <TextField
                          name="email"
                          variant="outlined"
                          fullWidth
                          label="Email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Button variant="contained" color="primary" onClick={this.handleSubmit} disabled={isLoader}>
                          {
                            isLoader ?       
                            <CircularProgress color="white" size={27} />
                            :
                            <span>Login</span>
                          }
                        </Button>
                      </Grid> 
                  </Grid>
                </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(withRouter(Add));