export const User = (data) => {
    return {
        type:'LOGIN',
        data,
    }
}

export const CurrentPaper = (data) => {
    return {
        type:'CURRENT_PAPER',
        data,
    }
}

export const CurrentTestScore = (data) => {
    return {
        type:'CURRENT_TEST_SCORE',
        data,
    }
}