import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import { Colors } from './Common/colors';
import { withStyles } from '@material-ui/styles';
import Meta from './Metas';
import { DOMAIN, DEFAULT_META } from './Constants';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const styles = theme => ({
    bannerWrapper : {
        padding : 30,
        minHeight: 350
    },
    headingWrapper : {
        textAlign : 'center',
        marginBottom : 30,
        marginTop : 30
    },    
    submitButton : {        
       background: Colors.PRIMARY
    },
    heading: {
        fontSize: 36
    },
    li: {
        padding: '0px !important'
    },
    iconWidth: {
        minWidth: '32px !important'
    }
});

class About extends Component{
    render(){
        const { classes } = this.props;
        return (
            <div>
                <Meta meta_title="About us - Mock Interview Questions" meta_description={DEFAULT_META.META_DESC_CONTACT} url={DOMAIN + '/contact-us'} />
                <Navbar />
                <div style={{height : 50}}></div>
                <div className={classes.bannerWrapper}>
                    <Container>
                        <div className={classes.headingWrapper}>
                            <div>
                                <Typography
                                    variant="h1"
                                    className={classes.heading}
                                    style={{paddingBottom: 10}}
                                >
                                    About us
                                </Typography>
                                <Typography>
                                    Our goal is to mentor students towards their dream jobs and careers.
                                </Typography>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">What Drives Us</Typography>
                                <Typography variant="p">Words like dedication, problem-solving abilities, and innovation define our values and philosophy of working. Our team is dedicated and they develop content that is easy to understand, relevant, apt, and of the highest quality. From free test series to paid online courses, everything is designed to motivate candidates and prepare them for interviews in the best possible way.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Our Vision</Typography>
                                <Typography variant="p">To become one of the most trusted test preparation platforms for any interview, competitive skills, government, and banking exams.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Our Mission</Typography>
                                <Typography variant="p">
                                    <List>                                    
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="To become India's most trusted platform to learn skills that you need for any technology job."
                                            />
                                        </ListItem>
                                                                     
                                        <ListItem className={classes.li}>
                                        <ListItemIcon className={classes.iconWidth}>
                                            <ArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="To polish your skills and get you prepared for the job doesn't matter if you are a fresh college graduate or an experienced working professional."
                                        />
                                        </ListItem>

                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="To get you connected with various worldwide companies based on your skills, knowledge, and preferences to make sure you get your dream job."
                                            />
                                        </ListItem>

                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="To prepare candidates for various entrance tests such as for banking, governmental exams, IT sectors, and other domains."
                                            />
                                        </ListItem>
                                    </List>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Our Impact</Typography>
                                <Typography variant="p">When it comes to the output or making any impact in interview results, OnlineMockInterview is the name to compute with. Our candidates successfully aced entrance examinations in various fields such as Banking, Government sectors, IT sector, Schooling, MBA, etc. Every year we prepared many toppers from different parts of the world. We are giving you the platform for practicing different test series now it's up to you how much you can practice as “Practice makes a man perfect”.</Typography>
                            </Grid>
                        </Grid>
                        <div style={{height : 50}}></div>
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withStyles(styles)(About);