import React, { Component } from 'react';
import { Button, Container, Grid, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import { Colors } from './Common/colors';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Meta from './Metas';
import { DOMAIN, DEFAULT_META } from './Constants';

const styles = theme => ({
    bannerWrapper : {
        background : Colors.SECONDARY,
        padding : 30,
        minHeight: 350
    },
    headingWrapper : {
        textAlign : 'center',
        marginBottom : 30,
        marginTop : 30
    },
    formContainer : {
        maxWidth: 1000,
        margin: '0px auto'
    },
    searchInput : {
        '& fieldset': {
            borderRadius: `4px 0px 0px 4px`,
        }
    },
    searchButton : {        
        borderRadius: `0px 4px 4px 0px`,
        height: '56px',
        background: Colors.PRIMARY
    },
    contactUSButton: {
        textAlign: 'center',
        display: 'inline-block',
        margin: '50px 0px'
    }
});

class Faq extends Component{
    render(){
        const { classes } = this.props;
        return (
            <div>
                <Meta meta_title={DEFAULT_META.META_TITLE_FAQ} meta_description={DEFAULT_META.META_DESC_FAQ} url={DOMAIN + '/faq'} />
                <Navbar />
                <div style={{height : 50}}></div>
                <div className={classes.bannerWrapper}>
                    <Container>
                        <div className={classes.headingWrapper}>
                            <div>
                                <Typography
                                    variant="h4"
                                    className={classes.heading}
                                    style={{paddingBottom: 10}}
                                >
                                   How can we help you from FAQs only?
                                </Typography>
                                <Typography>
                                Expectations from the site and candidate / We want to help you.
                                </Typography>
                            </div>
                        </div>
                        <Grid 
                            container
                            spacing={2}
                            className={classes.formContainer}
                        >
                        <Grid container style={{margin: '0px auto', marginBottom: '30px'}} item xs={8} >
                            <Grid item xs={8} className={classes.searchInput}>
                                <TextField
                                    autoComplete="keyword"
                                    name="keyword"
                                    variant="outlined"
                                    fullWidth
                                    value=""
                                    label="Search your query"                   
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.searchButton}                                 
                                    >
                                        Search Your Query
                                </Button>
                            </Grid>
                        </Grid>
                        <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading}>I am a bit confused to decide on a plan. Can you help me?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                A candidate should choose a package that is most suitable according to his needs. But,
if you ask us, we will suggest you go with the "Standard" plan which covers almost all features.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                <Typography className={classes.heading}>What should I do if I need some technical assistance?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                If you are facing some technical issues, the IT Service Desk should be your first point of
contact. In most cases, they will be able to resolve your queries. Still, you are fighting with it then please write us the issue you are facing by clicking the “Couldn’t Find Help?
Raise a Question” button below these questions.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid container className={classes.contactUSButton}>
                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() => this.props.history.push('/contact-us') }
                            >
                                Couldn't find help? Raise a question
                            </Button>
                        </Grid>                            
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}
export default withStyles(styles)(Faq);