import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { FormControlLabel, Snackbar, Switch } from '@material-ui/core';
import { API_URL } from '../../Constants';
import MuiAlert from "@material-ui/lab/Alert";
import { withRouter } from 'react-router-dom';
import ListIcon from '@material-ui/icons/FormatListBulleted';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  formControl:{
    width:"100%"
  },
  buttons: {
    marginRight: 10
  }
});

class AddCategory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      disabled: false,
      title: '',      
      selectedFile: null,
      displayMSG: null
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });    
  };

  handleClose = () => this.setState({ open: false });

  PageLink = () => this.props.history.push('/dashboard/gallery/list');

  onFileUpload = async () => {
    this.setState({disabled: true});
    var fd = new FormData();
    fd.append("img", this.state.selectedFile, this.state.selectedFile.name);
    fd.append('title', this.state.title);
      axios({
          method:"POST",
          url:API_URL+"gallery/add",
          data:fd,
          headers: {"Authorization" : `Bearer ${this.props.userdata.token}`, 'content-type': 'multipart/form-data'}
        })
        .then(data => {
          this.setState({
            open: true,
            displayMSG: 'You have successfully uploaded your file.',
            selectedFile: null,
            title: ''
          });
          setTimeout(() => {
            this.props.history.push('/dashboard/gallery/list');
          }, 1000)
        })
        .catch(e => console.log(e));
        this.setState({disabled: false});
  };

  render() {   
    let { classes } = this.props;
    let { disabled, open, displayMSG } = this.state;
    
    return (
          <Container className={classes.container}>  
            <Card variant="outlined">
              <CardContent>
              <Button color="primary" variant="outlined" onClick={()=>this.PageLink()} size="large" startIcon={<ListIcon />} style={{marginBottom: '10px'}}>
                Gallery List
              </Button>
              <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                  
                <MuiAlert
                  onClose={this.handleClose}
                  severity="success"
                  elevation={6}
                  variant="filled"
                >
                  {displayMSG}
                </MuiAlert>                  
              </Snackbar>

                <form>
                <Grid container spacing={2} style={{padding: '20px 0px'}}>                   
                  <Grid item sm={6}>       
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      size="medium"
                      color='primary'
                      onChange={this.onFileChange}
                    >
                      Upload File
                      <input
                        id="img"
                        hidden
                        name="img"
                        type="file"
                        accept="image/*"
                        onChange={this.onFileChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item sm={6}>               
                    <TextField
                      name="title"
                      size='small'
                      variant="outlined"
                      fullWidth
                      label="Enter title"       
                      value={this.state.title}
                      required
                      onChange={this.handleChange}
                    />
                  </Grid>                  
                  <Grid item sm={12} style={{textAlign: 'right'}}>
                    <Button variant="contained" color="primary" onClick={this.onFileUpload} disabled={disabled}>Upload</Button>
                  </Grid>                  
              </Grid>
              </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(withRouter(AddCategory));