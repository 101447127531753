import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { API_URL } from '../../Constants';
import axios from 'axios';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  }
});

const renderDetailsButton = (params) => {
  return (
        <>  
          <IconButton onClick={() => { editRecord(params.row.id) }}><EditIcon color="primary" /></IconButton>
          <IconButton onClick={() => { removeRecord(params.row.id) }}><DeleteIcon color="error" /></IconButton>
        </>
  )
}

const editRecord = (id) => {

}

const removeRecord = (id) => {
  console.log(id);
}

const columns = [
  { field: 'id', headerName: 'S.n', width: 120},
  { field: 'parent', headerName: 'Parent', width: 200 },
  { field: 'category', headerName: 'Category', width: 200 },
  { field: 'status', headerName: 'Status', width: 200 },
  { field: 'created_date', headerName: 'Created Date', width: 200 },
  { field: 'actions', headerName: 'Actions', width: 200, renderCell: renderDetailsButton }
];

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {  
      categoryArray: []
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories=(e)=>{
    axios({method:"POST",url:API_URL+"allcategories", data:{}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                              
      .then(data => {
        let result = data.data.data;         
           let categoryArray = result.map((item, index) => {
              return {            
                  id : index+1,
                  parent : item.parent,
                  category : item.category,
                  status : (item.status === 1) ? 'Active' : 'Inactive',
                  created_date : item.created_at,
              }          
          });
        this.setState({
          categoryArray: categoryArray
        });                     
      }).catch(error=>console.log(error));
  }

  render() {  
    let { classes } = this.props;
    
    return (
        <Container className={classes.container}>
          <Card className={classes.root} variant="outlined">
          <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            All Categories
          </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                {
                  this.state.categoryArray.length > 0 ?             
                  <DataGrid
                    rows={this.state.categoryArray}
                    autoHeight={true}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                  />
                  :
                  <LinearProgress />
                }
              </Grid>
            </Grid>
            </CardContent>
          </Card>
        </Container>
    );
  }
}
export default withStyles(styles)(Dashboard);