import React from 'react';
import Footer from '../Frontend/Footer/Footer';
import Navbar from '../Frontend/Navbar/Navbar';
import QuestionSection from '../Frontend/QuestionSection/QuestionSection';
import { withRouter } from 'react-router-dom';
import TestSection from '../Frontend/TestSection/TestSection';
import Meta from '../Metas';
import { DOMAIN, DEFAULT_META } from '../Constants';

class List extends React.Component {

  constructor(props) {
    super(props);
    this.state = {    
      tests: [],
      open: false
    };
  }

  render() {
    return <div>              
              <Meta meta_title={DEFAULT_META.META_TITLE_TST_LIST} meta_description={DEFAULT_META.META_DESC_TST_LIST} url={DOMAIN + '/online-test'} />
              <Navbar />
              <div style={{height : 50}}></div>
              <TestSection title='Online Assessments' description='Looking to prepare for a specific technology? Below tests are perfect if you want to prepare for for an IT Sector, Banking, Railways, Schooling, etc.
Choose your free test series from the given list.' container='h1'/>
              <QuestionSection />
              <Footer />
          </div>;
    }
}

export default (withRouter(List));