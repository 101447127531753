import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import { API_URL } from '../Constants';
import { withRouter } from 'react-router-dom';
import Navbar from '../Frontend/Navbar/Navbar';
import QuestionSection from '../Frontend/QuestionSection/QuestionSection';
import Footer from '../Frontend/Footer/Footer';
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Colors } from '../Common/colors';
import {Helmet} from "react-helmet-async";

const styles = (theme) => ({
  bannerWrapper : {
    background : Colors.SECONDARY,
    padding : 50,
    marginTop: '50px'
  },
  Paper: {
    maxWidth: '400px',
    margin: '0px auto',
    padding: '30px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: Colors.PRIMARY
  },
  form: {
    margin: '20px 0px',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: '0px !important'
    }
  },
  link: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
    display: 'block',
    color: '#000',
    padding: '5px 0px'
  }
});
class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: '',
      nameHelperText: '',
      email: '',
      emailError: '',
      emailHelperText: '',
      phone: '',
      phoneError: '',
      phoneHelperText: '',
      isLoader: false,
      message: '',
      errorType: '',
      displayMSG: false
    }
  }
    
  handleChange = (event) => {
    const name = event.target.name;
    this.setState({
      [name]: event.target.value,
    });
  };

  validName = () => {
    if(!this.state.name) {
      this.setState({
        nameError: true,
        nameHelperText: "Please enter your name"
      })
    } else {
      this.setState({
        nameError: false,
        nameHelperText: ""
      })
      return true;
    }
  }

  validEmail = () => {
    if(!this.state.email.includes("@")) {
      this.setState({        
        emailError: true,
        emailHelperText: "Please enter a valid email"
      })
    } else {
      this.setState({
        emailError: false,
        emailHelperText: ""
      })
      return true;
    }
  }

  validPhone = () => {
    let pattern = new RegExp(/^[0-9\b]+$/);    
    if(!pattern.test(this.state.phone)) {
      this.setState({        
        phoneError: true,
        phoneHelperText: "Please enter a valid phone number"
      })
    } else {
      this.setState({
        phoneError: false,
        phoneHelperText: ""
      })
      return true;
    }
  }
  
  onSubmit = () => {
    if(this.validName() && this.validEmail() && this.validPhone()) {
        this.setState({
          isLoader: true
        });
        const params = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          user_type: 8
        }
        axios({method:"POST",url:API_URL+"signup",data: params})                                 
          .then(data => {
              const user_id = data.data.data.user_id;
              this.props.history.push({ pathname: '/verify', state: { user_id: user_id, type: '/signup'}});    
          }).catch(error => {
            this.setState({
              message: error.response.data.message,
              errorType: error.response.data.statusCode,
              displayMSG: true,
              isLoader: false
            });
          });
   }
  }

  goToLink = () => {
    this.props.history.push('/login');
  }

  render() {    
    const { classes } = this.props;
    const { name, nameHelperText, nameError, email, emailHelperText, emailError, phone, phoneHelperText, phoneError, isLoader, displayMSG } = this.state;
    return <div>
      <Helmet>
          <title>Signup - Online Mock Interviews</title>
          <meta name="description" content="Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills." />
          <link rel="canonical" href="https://www.onlinemockinterviews.com/signup" />
      </Helmet>
      <Navbar />
      <div className={classes.bannerWrapper}>  
        <Container>
          <Paper elevation={4} className={classes.Paper}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          </Grid>
          <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          </Grid>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              {
                displayMSG && (
                  <Grid item xs={12} sm={12}>          
                    <Alert severity="error" style={{marginBottom:"5px"}}>
                      <AlertTitle>Error: {this.state.message}</AlertTitle>                  
                    </Alert>
                  </Grid>
              )}
              <Grid item xs={12} sm={12}>          
                <TextField
                  autoComplete="fname"
                  name="name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  label="Name"
                  error={nameError}
                  helperText={nameHelperText}
                  autoFocus
                  onChange={this.handleChange}
                />
              </Grid>          
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  value={email}
                  label="Email"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange}
                  error={emailError}
                  helperText={emailHelperText}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="phone"
                  name="phone"
                  variant="outlined"
                  fullWidth
                  value={phone}
                  label="Phone"
                  onChange={this.handleChange}
                  error={phoneError}
                  helperText={phoneHelperText}
                  required
                />
              </Grid>                   
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.onSubmit}
              size="large"
              disabled={isLoader}
            >
              {
                isLoader ?       
                <CircularProgress color="white" size={27} />
                :
                <span>CREATE ACCOUNT</span>
              }
            </Button>
            <Grid container justify="flex-end">            
                <Grid item>
                <Typography style={{color: '#999', fontSize: 11.6}}>Onlinemockinterviews is anonymous and your information is safe. </Typography>          
                  <Link onClick={this.goToLink} className={classes.link}>
                    {"Already have an account? Login"}
                  </Link>
                </Grid>
              </Grid>
          </form>
          </Grid>
          </Paper>
        </Container>
    </div>
    <QuestionSection />
    <Footer />
    </div>;
  }
}

export default withStyles(styles)(withRouter(SignUp));