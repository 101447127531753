import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, LinearProgress, IconButton } from '@material-ui/core';
import { Delete, Add, Close } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { getCompanyUserList } from '../../Method.util';

const useStyles = makeStyles({
  TestContainer: {
    background: '#000'
  },
  spacing: {
    marginBottom: 10
  }
});

const TestUsers = (props) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [isAddUser, setIsAddUser] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {    
      const params = {
        token: props.token
      }
      const result = await getCompanyUserList(params);
      if(result.success) {
        setUserList(result.data);
        setLoader(false);
      }
  }

  const openAddUserForm = () => {
    setIsAddUser(true);
  }

  const closeAddUserForm = () => {
    setIsAddUser(false);
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 250},    
    {
      field: 'email',
      headerName: 'Email', 
      width: 350
    }
  ];

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="max-width-dialog-title" fullWidth={true} maxWidth="md">
         <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"            
          >
          <Grid item xs={9}>
            <DialogTitle id="max-width-dialog-title">Add / Update Users</DialogTitle>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <IconButton>
              <Close onClick={props.handleClose} />
            </IconButton>
          </Grid>
        </Grid>
      <DialogContent>        
        <Grid container>
          <Grid item><Button size="small" variant="outlined" color="primary" onClick={openAddUserForm}>Add new user</Button></Grid>
          {
            isAddUser && (
              <Grid item container className={classes.spacing} spacing={2} alignItems="center" >            
                <Grid item xs={4}>
                  <TextField                    
                    margin="dense"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField                    
                    margin="dense"
                    name="email"
                    id="email"
                    label="Email Address"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" color="primary" onClick={openAddUserForm}>Submit</Button>
                  <IconButton>
                    <Close onClick={closeAddUserForm} />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }                 
          <Grid item xs={12} style={{marginTop: 20}}>
                {
                  !loader ? (
                    <div style={{ height: 300, width: '100%' }}>
                    <DataGrid
                      rows={userList}
                      columns={columns}
                      pageSize={3}
                      rowsPerPageOptions={[3]}
                      checkboxSelection
                      disableSelectionOnClick
                    />
                    </div>
                  ) : (
                      <LinearProgress />
                  )}
              </Grid>
              </Grid>
        
      </DialogContent>
      
    </Dialog>
      );
}
 
export default TestUsers;