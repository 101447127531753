const initialState = {
    paper: {},
}
const todos = (state = initialState, action) => {
    switch(action.type) {
        case "CURRENT_PAPER":
            return {
                ...state,
                paper: action.data
            };
        default:
            return state;
    }
}
export default todos;