import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withRouter, Link } from 'react-router-dom';
import CategoryIcon from '@material-ui/icons/Category';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AddIcon from '@material-ui/icons/Add';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GroupAdd from '@material-ui/icons/GroupAdd';
import Person from '@material-ui/icons/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CollectionsIcon from '@mui/icons-material/Collections';
import ListIcon from '@material-ui/icons/List';
import Payments from '@mui/icons-material/Payments';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    minHeight: 500
  },
  icon: {
    minWidth: '24px',
  }
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, 
      categoryopen: false, 
      testopen: false
    }
  }
  handleClick = () => { this.setState({ open: !this.state.open}); };
  handleCategoryClick = () => { this.setState({ categoryopen: !this.state.categoryopen }); };
  handleTestClick = () => { this.setState({ testopen: !this.state.testopen }); };
  logout = () => {
    localStorage.removeItem("userData");
    window.location.reload();
  }
  
  render() {
    const { classes, userdata } = this.props;
    return <div className={classes.root}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
    <ListItem button component={Link} to="/dashboard/index">
      <ListItemIcon style={{minWidth:'36px'}}>
        <DashboardIcon/>
      </ListItemIcon>
      <ListItemText primary="Dashboard" onClick={this.dashboardLink}/>
    </ListItem>
    {
      userdata.user_type == 1 && (
        <>
        <ListItem button onClick={this.handleCategoryClick}>
            <ListItemIcon style={{minWidth:'36px'}}>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Categories" />
            {this.state.categoryopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.categoryopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/dashboard/category/list">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Categories" />
            </ListItem>
            <ListItem button component={Link} to="/dashboard/category/add">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add Category" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={this.handleClick}>
            <ListItemIcon style={{minWidth:'36px'}}>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="Questions" />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/dashboard/question/list">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Questions" />
            </ListItem>
            <ListItem button component={Link} to="/dashboard/question/add">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Add Question" onClick={this.addQuestionLink}  />
            </ListItem>
          </List>
        </Collapse>
      </>
    )}       
    <ListItem button onClick={this.handleTestClick}>
        <ListItemIcon style={{minWidth:'36px'}}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Online Test" />
        {this.state.testopen ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={this.state.testopen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button component={Link} to="/dashboard/test/list">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="All Tests" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/test/add">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Create Test" />
        </ListItem>
      </List>
    </Collapse>    
    <ListItem button component={Link} to="/dashboard/users/list">
      <ListItemIcon style={{minWidth:'36px'}}>
        <GroupAdd />
      </ListItemIcon>
      <ListItemText primary="Manage Users" />
    </ListItem>
    {
      userdata.user_type == 1 && (
      <ListItem button component={Link} to="/dashboard/gallery/list">
        <ListItemIcon style={{minWidth:'36px'}}>
          <CollectionsIcon />
        </ListItemIcon>
        <ListItemText primary="Gallery" />
      </ListItem>
      )}
    </List>
    <List
    component="nav"
    aria-labelledby="nested-list-subheader"    
    className={classes.root}
  >
  <Divider />
    <ListSubheader>Personal Settings</ListSubheader>
    <ListItem button component={Link} to="/dashboard/package">
      <ListItemIcon style={{minWidth:'36px'}}>
        <Payments />
      </ListItemIcon>
      <ListItemText primary="Package" />
    </ListItem>
    <ListItem button component={Link} to="/dashboard/profile">
      <ListItemIcon style={{minWidth:'36px'}}>
        <Person />
      </ListItemIcon>
      <ListItemText primary="Manage Profile" />
    </ListItem>   
    <ListItem button onClick={() => this.logout()}>
      <ListItemIcon style={{minWidth:'36px'}}>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
    </List>
  </div>;
  }
}
export default withStyles(styles)(withRouter(Sidebar));