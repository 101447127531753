import React, { Component } from 'react';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import Pricing from './Frontend/Pricing/Pricing';
import QuestionSection from './Frontend/QuestionSection/QuestionSection';
import { DOMAIN, DEFAULT_META } from './Constants';
import Meta from './Metas';

class PricingPage extends Component{   
    render(){
        return (
            <div>
                <Meta meta_title={DEFAULT_META.META_TITLE_PRICING} meta_description={DEFAULT_META.META_DESC_PRICING} url={DOMAIN + '/pricing'} />
                <Navbar />
                <div style={{height : 50}}></div>
                
                <Pricing />
                <div style={{height : 50}}></div>
                <QuestionSection />
                <Footer />
            </div>
        )
    }
}

export default PricingPage;