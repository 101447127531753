import React, { Component } from 'react';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import StepSection from './Frontend/StepSection/StepSection';
import {Helmet} from "react-helmet-async";

class GetStarted extends Component{
    render(){
        return (
            <div>
                <Helmet>
                    <title>How to get started - Online Tests</title>
                    <meta name="description" content="Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills." />
                    <link rel="canonical" href="https://www.onlinemockinterviews.com/pricing" />
                </Helmet>
                <Navbar />
                <div style={{height : 50}}></div>
                <StepSection />
                <Footer />
            </div>
        )
    }
}

export default GetStarted;