import axios from 'axios';
import { API_URL, SUCCESS_RES_CODE } from '../Component/Constants';

export const getQuestionListFromTest = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const result = await axios.post(API_URL+"get-test-questions", options);
        if (result.status === SUCCESS_RES_CODE) {
            const data = result.data.data.questions;
            response.success = true;
            response.data = typeof data === 'undefined' ? [] : data;
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}

export const removeQuestions = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const headers = {
            headers: {"Authorization" : `Bearer ${options.token}`}
        };
        const result = await axios.post(API_URL+"remove-question-from-test", options, headers);
        if (result.status === SUCCESS_RES_CODE) {
            response.success = true;
            response.message = 'Item deleted successfully!'
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}


export const updateProfile = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const headers = {
            headers: {"Authorization" : `Bearer ${options.token}`}
        };
        const result = await axios.post(API_URL+"updateProfile", options, headers);
        if (result.status === SUCCESS_RES_CODE) {
            response.success = true;
            response.message = result.data.message
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}

export const copyTextToClipboard = async(text) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export const sendNotificationToUser = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const headers = {
            headers: {"Authorization" : `Bearer ${options.token}`}
        };
        const result = await axios.post(API_URL+"send-paper-notification-tousers", options, headers);
        if (result.status === SUCCESS_RES_CODE) {
            response.success = true;
            response.message = result.data.message
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}

export const addCompanyUser = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const headers = {
            headers: {"Authorization" : `Bearer ${options.token}`}
        };
        const result = await axios.post(API_URL+"addcompanyusers", options, headers);
        if (result.status === SUCCESS_RES_CODE) {
            response.success = true;
            response.message = 'User added successfully!'
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}
export const getCompanyUserList = async (options) => {
    const response = { success: false, message: '', errCode: '', data: null };
    try {
        const headers = {
            headers: {"Authorization" : `Bearer ${options.token}`}
        };
        const result = await axios.post(API_URL+"getcompanyusers", options, headers);
        if (result.status === SUCCESS_RES_CODE) {
            const data = result.data.data;
            response.success = true;
            response.data = typeof data === 'undefined' ? [] : data;
        }
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            response.message = e.response.data.message;
            response.errCode = e.response.data.code;
        }
    }
    return response;
}