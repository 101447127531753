import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { Snackbar, Typography } from '@material-ui/core';
import { API_URL } from '../../Constants';
import MuiAlert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  formControl:{
    width:"100%"
  }
});

class AddCategory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {     
      category_id: 0,
      categoryArray: [],
      category: null,
      description: null,
      open: false
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories=(e)=>{
    axios({method:"POST",url:API_URL+"allcategories",data:{status:1}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        this.setState({            
            categoryArray : data.data.data.map(item=>{
                return {
                    category : item.category,
                    id : item.id
                }
            })
        })                                  
      }).catch(error=>console.log(error));
  }

  handleSubmit = (e) => {
    const params = {
      category: this.state.category,   
      parent_id: this.state.category_id,
      description: this.state.description
    }
    axios({method:"POST",url:API_URL+"category/add",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                        
        .then(data => {
          this.setState({
            open: true,
            category: '',   
            parent_id: 0,
            description: ''
          });
        })
        .catch(error=>console.log(error));
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  handleClose = () => this.setState({ open: false });

  render() {  
    let { classes } = this.props;
    
    return (
          <Container className={classes.container}>          
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Add Category
                </Typography>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                  
                  <MuiAlert
                    onClose={this.handleClose}
                    severity="success"
                    elevation={6}
                    variant="filled"
                  >
                    Category Added successfully
                  </MuiAlert>
                  
                </Snackbar>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>                      
                      <Grid item xs={6} sm={6}>             
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Parent Category</InputLabel>
                          <Select
                            native
                            value={this.state.category_id}
                            onChange={this.handleChange}
                            label="Parent Category"
                            name="category_id"
                          >
                            <option aria-label="None" value="" />
                            {
                              this.state.categoryArray.map((item, index) => {
                                return <option value={item.id} key={index}>{item.category}</option>
                              })
                            }
                          </Select>
                        </FormControl>                        
                      </Grid>
                      <Grid item xs={6} sm={6}>               
                        <TextField
                          name="category"
                          variant="outlined"
                          fullWidth
                          label="Category"                          
                          value={this.state.category}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>               
                        <TextField
                          name="description"
                          variant="outlined"
                          fullWidth
                          label="Description"                          
                          value={this.state.description}
                          onChange={this.handleChange}
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={this.handleSubmit}>Add Category</Button>
                        <Button variant="contained" color="secondary">Reset</Button>
                      </Grid> 
                  </Grid>
                </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(AddCategory);