export const API_URL  = 'https://api.onlinemockinterviews.com:8020/';
export const DOMAIN  = 'https://www.onlinemockinterviews.com';
export const API_BASE_URL  = 'https://api.onlinemockinterviews.com/';

// export const API_URL  = 'http://localhost:8020/';
// export const API_BASE_URL  = 'http://localhost/mock-api/';
// export const DOMAIN  = 'http://localhost:3000';
export const DEFAULT_META = {
    META_TITLE : 'Online Mock Interview Test Platform Jobs',
    META_DESC : 'Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills.',
    META_TITLE_PRICING : 'Pricing - Online Mock Interview Test\'s Platform',
    META_DESC_PRICING : 'Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills.',
    META_TITLE_TST_LIST : 'Online Test Papers Jobs - Online Mock Interview',
    META_DESC_TST_LIST : 'Practice the Online interview Test and find out how much you score before you appear for your next interview and written test.',
    META_TITLE_CONTACT : 'Contact us - Online Mock Interviews',
    META_DESC_CONTACT : 'Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills.',
    META_TITLE_FAQ : 'FAQ - Online Mock Interviews',
    META_DESC_FAQ : 'Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills.',
};

export const BASIC_TIMER_MINT = 30;
export const SUCCESS_RES_CODE = 200;