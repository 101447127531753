import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import QuestionSectionImage from '../../Assets/Images/have_questions.png';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    questionWrapper : {
        margin : '40px 0',
        position : 'relative'
    },
    m20 : {
        margin : "10px 0 20px"
    },
    bookButton: {
        fontSize : 20
    },
    questionImage : {
        width : '70%',
        margin : '0 auto'
    },
    questionImageWrapper : {
        textAlign : 'center'
    }
});

class QuestionSection extends Component{
    redirectfaQPage = () => {
        this.props.history.push('/faq');
    }

    render(){
        const { classes } = this.props;
        return (
            <div className={classes.questionWrapper}>
                <Container>
                    <Grid container spacing={4} alignItems='center'>
                        <Grid item md={4}>
                            <Typography
                                variant="h4"
                            >
                                Help & Support
                            </Typography>
                            <Typography
                                className={classes.m20}
                            >
                           We would be happy to resolve your questions and love to hear from you. Visit our FAQ's page to check for your query.
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.redirectfaQPage}
                            >
                                Book a session
                            </Button>
                        </Grid>
                        <Grid item md={8}>
                            <div className={classes.questionImageWrapper}>
                                <img src={QuestionSectionImage} alt="question_image" className={classes.questionImage}/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(QuestionSection));