import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";

class displayMSG extends React.Component {
  
  render() {
    const { display, errorType, message } = this.props;
    if(!display) {
      return null;
    }
      return (                  
          <>            
            {
              displayMSG ?          
                <Snackbar open={display} onClose={this.props.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                  <MuiAlert
                    onClose={this.props.handleCloseDisplayMSG}
                    severity={errorType == 200 ? 'success' : 'warning'}
                    elevation={6}
                    variant="filled"
                  >
                    {message}
                  </MuiAlert>
                </Snackbar>
                :
                null
            }
          </>  
      );
    }
}
export default displayMSG;