import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Footer from '../Frontend/Footer/Footer';
import Navbar from '../Frontend/Navbar/Navbar';
import Ap from './Questions';
import { Button, CircularProgress, Dialog, DialogContent, Grid, LinearProgress, TextField } from '@material-ui/core';
import axios from 'axios';
import { API_URL, DOMAIN } from '../Constants';
import {connect} from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Colors } from '../Common/colors';
import { CurrentPaper } from '../Store/actions/Actions';
import Meta from '../Metas';

const styles = (theme) => ({
  bannerWrapper : {
    background: 'linear-gradient(to right, rgb(220,240,252, 90%), rgb(220,240,252, 15%))',
    padding : 50,
    marginTop: '50px',
    "@media (max-width: 767px)": {
      padding : '30px 0px'
    }
  }
});

class Tests extends React.Component {

  constructor() {
    super();
    this.state = {      
      isLoader: false,
      displayMSG: false,
      message: '',
      quiz: [],
      test_id: 0,
      isPrivate: false,
      emailHelperText: '',
      emailError: '',
      email: '',
      company_user_id: 0,
      isloadQuestionPage: false,
      loader: true
    };
  }

  componentDidMount() {
    this.startTest();
  }

  startTest = () => {
    const slug = this.props.match.params.slug
      this.setState({
        open: true
      });
  
      const params = {
        slug: slug
      }
      axios({method:"POST",url:API_URL+"get-test-details", data:params})
        .then(data => {
          this.props.dispatch(CurrentPaper(data.data.data));
          this.validateTestDataExists();
        }).catch(error => {
          this.props.history.push('/online-test');
        });
        setTimeout(() => {
          this.setState({
            open: false
          }, () => {
            this.props.history.push(slug); 
          });
      }, 1000)
  }

  validateTestDataExists = () => {
    const { testdata } = this.props;
    if(testdata && testdata.id) {
      this.setState({
        test_id: this.props.testdata.id,
        isPrivate: (testdata.visibility === 'Private') ? true : false
      });
      this.getTestBySlug();
    } else {
      this.props.history.push('/online-test');
    }
  }

  getTestBySlug = () => {
    const params = {
      id: this.props.testdata.id
    }
    axios({method:"POST",url:API_URL+"get-test-questions", data:params})
      .then(data => {
        if(data.data.data.questions.length === 0) {
          this.props.history.push('/online-test');
        }
        this.setState({
          quiz: data.data.data.questions,
          loader : false
        });
      }).catch(error => {        
        this.props.history.push('/online-test');
        this.setState({
          loader : false
        });
      });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({
      message: ''
    })
    const { testdata } = this.props;
    const selectedUsers = testdata.selectedUsers;
    if(!this.state.email || !this.state.email.includes("@")) {
      this.setState({  
        emailError: true,
        emailHelperText: "Please enter a valid email",
        isLoader: false
      })
    } else {
      this.setState({
        emailError: false,
        emailHelperText: "",
        isLoader: true
      }, () => {
        const getUserDetails = selectedUsers.filter((user) => {
          return user.email === this.state.email
        });
        if(getUserDetails.length > 0) {
          if(!getUserDetails[0].is_completed) {
            this.setState({
              isloadQuestionPage: true,
              company_user_id: getUserDetails[0].id,
              message: ''
            }, () => {
              setTimeout(() => {
                this.setState({
                  isPrivate: false
                })
              }, 2000);
            })
          } else {
            this.setState({
              message: 'You have already submitted your test.',
              isLoader: false,
              email: ''
            })
          }
        } else {
          this.setState({
            emailError: true,
            emailHelperText: "This email is not registered with us. Please enter a different email",
            isLoader: false
          })
        }
      })
    }    
  }

  render() {
    let { classes, testdata, history } = this.props;
    const { quiz, isPrivate, email, isLoader, emailHelperText, emailError, company_user_id, isloadQuestionPage, message, loader } = this.state;
    return <div>
              <Navbar />
              <div className={classes.bannerWrapper}>
              <Container style={{minHeight: '430px'}}>
                {
                  loader ? (
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{marginTop: '50px'}}
                      >
                        <Grid item xs={3}>
                          <CircularProgress />
                        </Grid>          
                      </Grid>
                  ) : (            
              <>
                {
                  !isPrivate ?           
                  <div className={classes.paper}>
                      {
                        quiz.length > 0 && (
                          <>
                            <Meta meta_title={testdata.meta_title} meta_description={testdata.meta_description} url={DOMAIN + '/' +testdata.slug} />
                            <Ap quiz={quiz} history={history} testdata={testdata} company_user_id={company_user_id} />
                          </>                          
                        )
                      }
                  </div>
                  :
                  <Dialog
                    open={true}
                    fullWidth
                    maxWidth="xs"
                  >
                    <DialogContent style={{minHeight: 100}}>
                      {
                        message && (
                          <Alert severity="warning" style={{marginBottom:"5px"}}>
                            {message}             
                          </Alert>
                        )
                      }                    
                      {
                        !isloadQuestionPage ?
                        <>                                   
                          <TextField
                            margin="dense"     
                            label="Email Address"
                            fullWidth
                            name="email"
                            value={email}
                            required
                            variant="standard"
                            onChange={this.handleChange}
                            error={emailError}
                            helperText={emailHelperText}
                          />                          
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            style={{marginTop: '10px', float: 'right', width: '120px'}}
                          >
                            {
                              isLoader ?                              
                                <CircularProgress color="white" size={25}/>                              
                              :
                              <span>Submit</span>
                            }
                          </Button>
                        </>
                        :
                        <div style={{position: 'relative', paddingTop: 40}}>
                          <LinearProgress/>
                        </div>
                      }
                    </DialogContent>
                  </Dialog>
                }
                </>              
                  )
              }
              </Container>
              </div>
            <Footer />
          </div>;
  }
}
const mapStateToProps = (state) => {
  return {
    testdata:state.CurrentPaper.paper
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Tests));