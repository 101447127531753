import { Container, Divider, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Colors } from '../../Common/colors';
import { Link } from 'react-router-dom';
const styles = theme => ({
    footerDiv : {
        background : Colors.PRIMARY,
        padding : '50px 0'
    },
    footerNav : {
        color : '#fff',
        margin : '15px 0',
        display: 'block',
        fontFamily : 'poppins',        
        textDecoration : 'none'
    },
    divider : {
        background : Colors.SECONDARY,
        marginTop: 10
    },
    textCenter : {
        textAlign : 'center'
    },
    footerNavtext : {
        color : '#fff',
        marginTop : 20
    },
    footerHeading: {
        color : '#fff',
        textTransform: 'uppercase',
        fontSize: 18
    }
});

class Footer extends Component{
    render(){
        const { classes } = this.props;
        return (
            <div className={classes.footerDiv}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item md={3} sm={12} xs={12}>
                            <Typography className={classes.footerHeading}>Our Popular MCQ's</Typography>
                            <Link className={classes.footerNav} to='/css-online-test'>CSS Online Test</Link>
                            <Link className={classes.footerNav} to='/html-online-test'>HTML Online Test</Link>
                            <Link className={classes.footerNav} to='/bootstrap-online-test'>Bootstrap Online Test</Link>
                            <Link className={classes.footerNav} to='/current-affairs-mcq'>Current Affairs MCQ's</Link>
                            <Link className={classes.footerNav} to='/reasoning-mcq'>Reasoning MCQ's</Link>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <Typography className={classes.footerHeading}>Interview Questions</Typography>
                            <a className={classes.footerNav} target="_blank" href='https://www.bestinterviewquestion.com/salesforce-admin-interview-questions'>Salesforce Admin Interview Questions</a>
                            <a className={classes.footerNav} target="_blank" href='https://www.bestinterviewquestion.com/snowflake-interview-questions'>Snowflake Interview Questions</a>
                            <a className={classes.footerNav} target="_blank" href='https://www.bestinterviewquestion.com/jmeter-interview-questions'>JMeter Interview Questions</a>
                            <a className={classes.footerNav} target="_blank" href='https://www.bestinterviewquestion.com/microsoft-interview-questions'>Microsoft Interview Questions</a>
                            <a className={classes.footerNav} target="_blank" href='https://www.bestinterviewquestion.com/critical-thinking-interview-questions'>Critical Thinking Interview Questions</a>
                        </Grid>
                        <Grid item md={2} sm={12} xs={12}>
                            <Typography className={classes.footerHeading}>PRIMARY PAGES</Typography>
                            <Link className={classes.footerNav} to='/about'>About</Link>
                            <Link className={classes.footerNav}>Blogs</Link>
                            <Link className={classes.footerNav}>Services</Link>
                            <Link className={classes.footerNav} to='/pricing'>Pricing</Link>
                            <Link className={classes.footerNav} to='/privacy-policy'>Privacy Policy</Link>
                        </Grid>                        
                        <Grid item md={1} sm={12} xs={12}>
                            <Typography className={classes.footerHeading}>SOCIAL</Typography>
                            <a target="_blank" className={classes.footerNav} href='https://www.facebook.com/Online-mock-interviews-100434365877060'><FacebookIcon /></a>
                            <a target="_blank" className={classes.footerNav} href='https://twitter.com/onlinemockinte1'><TwitterIcon /></a>
                            <Link className={classes.footerNav}><LinkedInIcon /></Link>
                            <Link className={classes.footerNav}><YouTubeIcon /></Link>
                        </Grid>
                        <Grid item md={2} sm={12} xs={12}>
                            <Typography className={classes.footerHeading}>SUPPORT</Typography>  
                            <Link className={classes.footerNav} to='/faq'>Have Questions?</Link>
                            <Link className={classes.footerNav} to='/contact-us'>Contact Us</Link>
                            <Link className={classes.footerNav} to='/faq'>FAQ's</Link>
                            <Link className={classes.footerNav} to='/how-to-get-started'>Getting Started</Link>
                        </Grid>
                    </Grid>
                    <Divider
                        className={classes.divider}
                    />
                    <div 
                        className={classes.textCenter}
                    >
                        <Typography
                            className={classes.footerNavtext}
                        >
                            Copyright © 2022 All rights reserved
                        </Typography>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withStyles(styles)(Footer);