import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, CircularProgress, Container, FormControl, Snackbar, Grid, IconButton, Tooltip, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import PageviewIcon from '@material-ui/icons/Link';
import { API_URL } from '../../Constants';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactHtmlParser from 'react-html-parser';
import MaterialTable, { MTableToolbar } from 'material-table';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  drawer: {
    padding: '10px 15px',
    width: 700
  },
  gridContainer: {
    marginTop: '25px'
  },
  dialogPaper: {
    minHeight: '200px'
  },
  dialogPaperRemoveConf: {
    minHeight: '100px'
  },
  titleHeader: {
    paddingLeft: 10
  }
});

class Add extends React.Component {

  constructor(props) {
    super(props);
    this.state = {      
      testLists:[],
      questionList: [],
      isloader: false,  
      displayMSG: false,
      errorType: null,
      openPopup: false,
      questionDetailsByID: [],
      viewQuestionID: 0,
      viewQuestionPopupLoader: false,
      isAletDialog: false,
      selectedQuestionID: 0,
      isloaderForConf: false
    };
  }

  componentDidMount() {
    this.getQuestionList();
  }

  alertDialogForTest = () => {
    this.setState( prevState => ({
      isAletDialog: !prevState.isAletDialog
    }));
  }

  removeQuestionByID = () => {
    this.setState({
      isloaderForConf: true
    });
    const params = {
      id: this.state.selectedQuestionID
    }
    axios({method:"POST", url:API_URL+"question/removebyid",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                  
      .then(data => {
        this.setState({
          displayMSG: true,
          message: 'You have deleted your test paper successfully.',
          errorType: 200,
        })
        setTimeout(() => {
          this.getQuestionList();
          this.setState({
            isAletDialog: false,
            isloaderForConf: false
          });
        }, 500)                                           
      }).catch(error=>console.log(error));
  }

  getQuestionList = () => {
    this.setState({
      isloader: true
    });
    axios({method:"POST",url:API_URL+"allquestions",data:{}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                     
      .then(data => {
        const dataNew = data.data.data.map((item, index) => {
          return {
              id:item.id,
              title:ReactHtmlParser(item.title),
              actual_status:item.actual_status,
              category:item.category,
              status : item.status ? <Button variant="outlined" color="info" size="small" onClick={() => this.updateStatus(item.id, item.actual_status)}>Active</Button> : <Button variant="outlined" color="secondary" size="small" onClick={() => this.updateStatus(item.id, item.actual_status)}>Deactive</Button>,
          }
        })
        this.setState({
          testLists:dataNew,
          isloader: false
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  getQuestionsByID = (e) => {
    this.setState({
      viewQuestionPopupLoader: true
    });    
    const params = {
      id: (this.state.viewQuestionID) ? this.state.viewQuestionID : 0
    }
    axios({method:"POST",url:API_URL+"getquestionbyid",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => { 
        const dataArray = data.data.data[0];
        this.setState({
          questionDetailsByID : {
            id: dataArray.id,
            title: dataArray.title,
            option_a: dataArray.option_a,
            option_b: dataArray.option_b,
            option_c: dataArray.option_c,
            option_d: dataArray.option_d,
            answer: dataArray[dataArray.answer]
          },
          viewQuestionPopupLoader: false
        });                               
      }).catch(error=>console.log(error));
  }

  handleCellClick = (param, event) => {
    event.stopPropagation();
  };

  handleRowClick = (param, event) => {    
    event.stopPropagation();
  };

  PageLink = params => {
    const page = '/dashboard/question/add';
    this.props.history.push(page);
  }

  handleCloseQuestionPopup = () => {
    this.setState({
      openPopup: false
    });
  }

  columns = [                        
    { title: 'Question', field: 'title' },
    { title: 'Category', field: 'category' },
    { title: 'Status', field: 'status' }
  ]

  actions = [           
    {
      icon: () => <EditIcon color="primary" />,
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        this.setState({
          selectedTestID: rowData.id
        });
        this.props.history.push({ pathname: '/dashboard/question/edit', state: { testid: rowData.id }});
      },
      position: "row"
    },
    {
      icon: () => <PageviewIcon color="primary" />,
      tooltip: 'View',
      onClick: (event, rowData) => {
        this.setState({
          viewQuestionID: rowData.id,
          openPopup: true
        }, () => {
          this.getQuestionsByID();
        })
      },
      position: "row"
    },
    {
      icon: () => <DeleteIcon color="error" />,
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        this.setState({
          selectedQuestionID: rowData.id
        }, () => {
          this.setState({
            isAletDialog: true
          });
        });
      },
      position: "row"
    }
  ];

  updateStatus = (id, currentStatus) => {
    console.log('id', id);
    console.log('currentStatus', currentStatus);
    // axios(
    //   {
    //     method:"POST",
    //     url:API_URL+"companyusers/updateStatus",
    //     data:{status:currentStatus, id},
    //     headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
    //   }
    //   ).then(data => {
    //     this.getUsers();       
    //     this.setState({          
    //       displayMSG: true,
    //       message: 'You have updated the status successfully.',
    //       errorType: 200,
    //     })
    //   }).catch(error=>console.log(error));
  }

  render() {  
    let { classes } = this.props;
    let { isloader, displayMSG, errorType, message, openPopup, viewQuestionPopupLoader, questionDetailsByID, isloaderForConf } = this.state;
    return (       
        <Container className={classes.container}>
          {
            displayMSG ?            
              <Snackbar open={displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                <MuiAlert
                  onClose={this.handleCloseDisplayMSG}
                  severity={errorType == '200' ? 'success' : 'warning'}
                  elevation={6}
                  variant="filled"
                >
                  {message}
                </MuiAlert>                  
              </Snackbar>
              :
              null
          }
          <Card className={classes.root} variant="outlined">          
          <CardContent>
            <Grid container spacing={1}>
              {
                !isloader ?  
                  <Grid item xs={12} sm={12}>            
                    <MaterialTable
                      title= {<Button color="primary" variant="outlined" size="large" startIcon={<AddIcon />} onClick={()=>this.PageLink('add')}>
                               ADD NEW QUESTION
                            </Button>}
                      columns={this.columns}
                      data={this.state.testLists}
                      options={{
                        draggable: false,
                        search: true,
                        sorting: true,
                        pageSize: 5,
                        paginationType: 'stepped',
                        showTitle: true,
                        toolbar: true,
                        maxBodyHeight: 500,
                        actionsColumnIndex: -1,
                        selection: true,
                      }}
                      actions={this.actions}
                      components={{
                        Container: props => props.children,
                        Toolbar: props => (
                          <MTableToolbar {...props} classes={{ root: classes.titleHeader }} />
                        )
                      }}
                    />
                  </Grid>
                  :
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{margin: '200px auto'}}
                  >
                    <Grid item xs={3}>
                    <CircularProgress />
                    </Grid>          
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
          <Dialog open={openPopup} onClose={this.handleCloseQuestionPopup} fullWidth maxWidth="sm" classes={{ paper : classes.dialogPaper}}>
            {
              !viewQuestionPopupLoader ?
                <>
                <DialogContent>
                  <DialogContentText>
                    {ReactHtmlParser(questionDetailsByID.title)}
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        defaultValue={questionDetailsByID.answer}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel value={questionDetailsByID.option_a} control={<Radio />} label={questionDetailsByID.option_a} />
                        <FormControlLabel value={questionDetailsByID.option_b} control={<Radio />} label={questionDetailsByID.option_b} />
                        <FormControlLabel value={questionDetailsByID.option_c} control={<Radio />} label={questionDetailsByID.option_c} />
                        <FormControlLabel value={questionDetailsByID.option_d} control={<Radio />} label={questionDetailsByID.option_d} />
                      </RadioGroup>
                    </FormControl>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>                     
                  <Button variant="contained" color="secondary" onClick={this.handleCloseQuestionPopup}>Close</Button>
                </DialogActions>
                </>
                :
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{marginTop: '50px'}}
                  >
                  <Grid item xs={3}>
                    <CircularProgress />
                  </Grid>          
                </Grid>
            }
          </Dialog>
          <Dialog
              open={this.state.isAletDialog}
              onClose={this.alertDialogForTest}
              fullWidth
              maxWidth="sm"
              classes={{ paper : classes.dialogPaperRemoveConf}}
            >
              {
                !isloaderForConf ?
                <>
                <DialogContent>
                  <DialogContentText>
                    <b>Please note that:-</b> Once you have delete this question then this want show any more.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" onClick={this.alertDialogForTest} size="small">Cancel</Button>                    
                  <Button variant="contained" color="secondary" onClick={this.removeQuestionByID} size="small">OK</Button>
                </DialogActions>
                </>
                :
                <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{marginTop: '30px'}}
                    >
                      <Grid item xs={3}>
                        <CircularProgress />
                      </Grid>          
                    </Grid>
              }
            </Dialog>
        </Container>
    );
  }
}
export default withStyles(styles)(withRouter(Add));