import React, { compose } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Navbar from '../Frontend/Navbar/Navbar';
import Footer from '../Frontend/Footer/Footer';
import OtpInput from 'react-otp-input';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import '../Company/Verify.css';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import { API_URL } from '../Constants';
import {connect} from 'react-redux';
import { User } from '../Store/actions/Actions';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Colors } from '../Common/colors';

const styles = (theme) => ({
  bannerWrapper : {
    background : Colors.SECONDARY,
    padding : 50,
    marginTop: '50px'
  },
  Paper: {
    maxWidth: '400px',
    margin: '0px auto',
    padding: '30px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: Colors.PRIMARY
  },
  form: {
    margin: '20px 0px',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: '0px !important'
    }
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',      
      isLoader: false,
      displayMSG: false,
      message: ''
    }
  }

  componentDidMount() {  
    if (this.props.location.state === undefined) {
      this.props.history.push('/');
      return;
    }
  }

  handleChange = (otp) => this.setState({ otp });

  verifyUser = () => {
    this.setState({
      isLoader: true
    });

    const params = {
      user_id: this.props.location.state.user_id,
      otp: this.state.otp,
      type: this.props.location.state.type
    }
    axios({method:"POST",url:API_URL+"validate-signup",data: params})                                 
      .then(data => {
          this.setState({
            isLoader: false
          }, () => {
            this.props.dispatch(User(data.data.data));
            localStorage.setItem("userData", JSON.stringify(data.data.data));
            this.props.history.push('/dashboard/index');
          });  
      }).catch(error => {
        if(error && error.response) {
          this.setState({
            message: error.response.data.message,
            displayMSG: true,
            isLoader: false
          });
        }
      });
  }

  render() {    
    let { classes } = this.props;
    let { isLoader, displayMSG, message } = this.state;
    return <div>
        <Navbar />   
        <div className={classes.bannerWrapper}>  
        <Container>
          <Paper elevation={4} className={classes.Paper}>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography component="h1" variant="h5">
                  Verify OTP
                </Typography>
              </Grid>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  {
                    displayMSG && (
                      <Grid item xs={12} sm={12}>          
                        <Alert severity="error" style={{marginBottom:"5px"}}>
                          <AlertTitle>Error: {message}</AlertTitle>                  
                        </Alert>
                      </Grid>
                    )
                  }
                  <Grid item xs={12} sm={12}>
                    <OtpInput
                      value={this.state.otp}
                      onChange={this.handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      className="optInput"
                      isInputNum={true}
                      isInputSecure={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={this.verifyUser}
                  disabled={isLoader}
                >          
                  {
                    isLoader ?       
                    <CircularProgress color="white" size={27} />
                    :
                    <span>VERIFY</span>
                  }
                </Button>
                </Grid>
                {/* <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Resend OTP
                      </Link>
                    </Grid>            
                  </Grid>      */}
              </form>
            </Grid>
          </Paper>
        </Container>
        </div>      
        <Footer />
      </div>;
  }
}

export default connect()(withStyles(styles)(withRouter(Verify)));