import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, Paper, Button, Typography, Avatar } from '@material-ui/core';
import { Email, Phone, Edit } from '@material-ui/icons';

import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
  },
  seprateBox: {
    textAlign: 'center',
    padding: 20,
    width: 500
  },
  spacing: {
    padding: '20px 0px'
  },
  PhoneIcon: {
    verticalAlign: 'middle'
  }
});

class Package extends React.Component {
  render() {
    let { classes } = this.props;
      return (        
          <Container className={classes.container}>
            <Typography variant="h5" component="div" className={classes.spacing}>
              Manage Your Package
            </Typography>
            <Card style={{paddingTop: 5, paddingLeft: 5}} variant="outlined">
              <CardContent>            
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >                  
                  <Paper elevation={3} className={classes.seprateBox}>                    
                      
                      <Typography variant="h5" component="div" className={classes.spacing}>
                        Coming Soon...
                      </Typography>
                              
                  </Paper>
                </Grid>
              </CardContent>
            </Card>
          </Container>  
      );
    }
}
export default withStyles(styles)(Package);