import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { stateToHTML } from "draft-js-export-html";
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../Constants';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/AddCircle';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';

const styles = (theme) => ({
  
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  editorStyle: {
    border:"1px solid #efefef",
    padding:"0px 20px",
    minHeight:"200px"
  },
  formControl:{
    width:"100%"
  }
});

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: null,
      option_a:null,
      option_b:null,
      option_c:null,
      option_d:null,
      answer: null,
      category_id: 0,
      question_level_id: 0,
      question_type_id: 0,
      title: null,
      categoryArray: [],
      questionsLevelArray: [],
      editorContentHtml: '',
      open: false
    };
  }

  componentDidMount() {
    this.getCategories();
    this.getQuestionLevel();
    this.getQuestionDetailByID();
  }

  getCategories=(e)=>{    
    axios({method:"POST",url:API_URL+"allcategories",data:{status:1}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        this.setState({            
            categoryArray : data.data.data.map(item=>{
                return {
                    title : item.category,
                    id : item.id
                }
            })
        })                    
      }).catch(error=>console.log(error));
  }

  getQuestionLevel=(e)=>{
    axios({method:"POST",url:API_URL+"allquestionlevel",data:{status:1}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        this.setState({
          questionsLevelArray : data.data.data.map(item=>{
                return {
                    title : item.title,
                    id : item.id
                }
            })
        })                         
      }).catch(error=>console.log(error));
  }

  getQuestionDetailByID = () => {
    axios({method:"POST",url:API_URL+"getquestionbyid", data:{id:this.props.id}, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})                                 
      .then(data => {
        const questionData = data.data.data[0];        
        const contentBlock = convertFromHTML(questionData.title);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          this.setState({
            editorState,
            editorContentHtml: stateToHTML(editorState.getCurrentContent())
          })
        }
        this.setState({
          category_id: questionData.skill_id,
          option_a: questionData.option_a,
          option_b: questionData.option_b,
          option_c: questionData.option_c,
          option_d: questionData.option_d,
          answer:  questionData.answer,
          question_level_id: questionData.question_level_id
        })                                  
      }).catch(error=>console.log(error));
  }

  handleSubmit = (e) => {
    const params = {
      id:this.props.id,
      title: this.state.editorContentHtml,
      question_type_id: this.state.question_type_id,
      question_level_id: this.state.question_level_id,
      category_id: this.state.category_id,
      option_a: this.state.option_a,
      option_b: this.state.option_b,
      option_c: this.state.option_c,
      option_d: this.state.option_d,
      answer: this.state.answer,
    }
    axios({method:"POST",url:API_URL+"updatequestionbyid",data:params, headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}})             
        .then(data => {
          this.setState({
            open: true,
            editorState: null,
            option_a: '',
            option_b: '',
            option_c: '',
            option_d: '',
            answer: '',
            category_id: 0,
            question_level_id: 0,
            question_type_id: 0,
            title: null,
            editorContentHtml: '',
          });
          setTimeout(() => {
            this.props.history.push('/dashboard/question/list');
          }, 1000)
        })
        .catch(error=>console.log(error));
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      editorContentHtml: stateToHTML(editorState.getCurrentContent())
    });
  };

  handleClose = () => this.setState({ open: false });

  PageLink = params => {    
    const page = (params === 'list') ? '/dashboard/question/list' : '/dashboard/question/add';
    this.props.history.push(page);
  }

  render() {  
    let { classes } = this.props;
    
    return (
          <Container maxWidth="lg" className={classes.container}>          
            <Card className={classes.root} variant="outlined">              
              <CardContent>                
                <Button color="primary" onClick={()=>this.PageLink('list')} variant="outlined" size="large" startIcon={<ListIcon />} style={{marginBottom: '20px', marginRight: '10px'}}>
                All Questions
              </Button>
              <Button color="primary" onClick={()=>this.PageLink('add')} variant="outlined" size="large" startIcon={<AddIcon />} style={{marginBottom: '20px'}}>
                ADD NEW Question
              </Button>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                  
                  <MuiAlert
                    onClose={this.handleClose}
                    severity="success"
                    elevation={6}
                    variant="filled"
                  >
                    You have successfully updated your question.
                  </MuiAlert>                  
                </Snackbar>

                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>                   
                    <Grid item xs={12} sm={12}>
                      <Editor              
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName={classes.editorStyle}
                        onEditorStateChange={this.onEditorStateChange}
                      />                      
                    </Grid>           
                  </Grid>   

                  <Divider variant="middle" style={{margin:"20px 0px"}} />

                    <Grid container spacing={2}>                    
                      <Grid item xs={6} sm={6}>          
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Question Type</InputLabel>                          
                          <Select
                            native
                            value={this.state.question_type_id}
                            onChange={this.handleChange}
                            label="questionType"
                            name="question_type_id"
                          >                                                
                            <option value="1" key="1">Radio</option>
                            <option value="2" key="2">Checkbox</option>
                            <option value="3" key="3">Input</option>
                            <option value="4" key="4">Textarea</option>
                          </Select>                          
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}> 
                      <Grid item xs={6} sm={6}>                      
                        <TextField                          
                          name="option_a"
                          variant="outlined"
                          fullWidth                          
                          placeholder="Option A"
                          value={this.state.option_a}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField                          
                          name="option_b"
                          variant="outlined"
                          fullWidth
                          placeholder="Option B"
                          value={this.state.option_b}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      
                      <Grid item xs={6} sm={6}>
                        <TextField                          
                          name="option_c"
                          variant="outlined"
                          fullWidth
                          placeholder="Option C"
                          value={this.state.option_c}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField                          
                          name="option_d"
                          variant="outlined"
                          fullWidth
                          placeholder="Option D"                          
                          value={this.state.option_d}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>               
                        <FormControl variant="outlined" className={classes.formControl}>                                              
                          <Select
                            native
                            value={this.state.answer}
                            onChange={this.handleChange}
                            placeholder="Answer"
                            name="answer"
                          >
                            <option aria-label="None" value="" />
                            <option value="option_a" key="option_a">A</option>
                            <option value="option_b" key="option_b">B</option>
                            <option value="option_c" key="option_c">C</option>
                            <option value="option_d" key="option_d">D</option>
                          </Select>                
                        </FormControl>
                      </Grid>
                    </Grid>
                    
                    <Divider variant="middle" style={{margin:"20px 0px"}} />

                    <Grid container spacing={2}> 
                      <Grid item xs={6} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Question Level</InputLabel>
                          <Select
                            native
                            value={this.state.question_level_id}
                            onChange={this.handleChange}
                            label="Question Level"
                            name="question_level_id"
                          >
                            <option aria-label="None" value="" />
                            {
                              this.state.questionsLevelArray.map((item, index) => {
                                return <option value={item.id} key={index}>{item.title}</option>
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6}>                        
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel htmlFor="outlined-age-native-simple">Category</InputLabel>
                          <Select
                            native
                            value={this.state.category_id}
                            onChange={this.handleChange}
                            label="category"
                            name="category_id"
                          >
                            <option aria-label="None" value="" />
                            {
                              this.state.categoryArray.map((item, index) => {
                                return <option value={item.id} key={index}>{item.title}</option>
                              })
                            }
                          </Select>
                        </FormControl>              
                      </Grid>
                      <Grid item xs={6} sm={6}>                   
                        <Button variant="contained" color="primary" onClick={this.handleSubmit}>Update</Button>
                      </Grid> 
                  </Grid>
                </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(withRouter(Dashboard));