import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Container, Grid, LinearProgress, Snackbar } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { API_URL } from '../../Constants';
import axios from 'axios';
import Moment from 'react-moment';
import AddIcon from '@material-ui/icons/AddCircle';
import { withRouter } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  titleHeader: {
    paddingLeft: 10
  }
});
const tableRef = React.createRef();

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      userLists: [],
      displayMSG: false,
      errorType: '',
      message: ''
    }
  }

  componentDidMount() {
    this.getUsers();
  }

  actions = [
    {
      icon: 'refresh',
      tooltip: 'Refresh Data',
      isFreeAction: true,
      onClick: () => this.getUsers()
    },
    {
      tooltip: 'Remove All Selected Users',
      icon: () => <DeleteIcon />,
      onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
    },
    {
      icon: () => <EditIcon color="primary" />,
      tooltip: 'Edit',
      onClick: (event, rowData) => {
        this.setState({
          selectedTestID: rowData.id
        });
        this.props.history.push({ pathname: '/dashboard/users/edit', state: { userid: rowData.id }});
      },
      position: "row"
    },
    {
      icon: () => <DeleteIcon color="error" />,
      tooltip: 'Delete',
      onClick: (event, rowData) => {},
      position: "row"
    }
  ];

  getUsers = (e) => {
    this.setState({
      loader: true
    })
    axios(
      {
        method:"POST",
        url:API_URL+"getcompanyusers",
        data:{},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        var users = data.data.data;
        this.setState({
          userLists : users.map(item => {
              return {
                id : item.id,
                name : item.name,
                company : item.company,
                email : item.email,
                status : item.status ? <Button variant="outlined" color="info" size="small" onClick={() => this.updateStatus(item.id, item.status)}>Active</Button> : <Button variant="outlined" color="secondary" size="small" onClick={() => this.updateStatus(item.id, item.status)}>Deactive</Button>,
                date : <Moment format="LL">{item.created_at}</Moment>
              }
          }),
          loader: false
        })
      }).catch(error=>console.log(error));
  }

  PageLink = () => this.props.history.push('/dashboard/users/add');

  updateStatus = (id, currentStatus) => {
    axios(
      {
        method:"POST",
        url:API_URL+"companyusers/updateStatus",
        data:{status:currentStatus, id},
        headers: {"Authorization" : `Bearer ${this.props.userdata.token}`}
      }
      ).then(data => {
        this.getUsers();       
        this.setState({          
          displayMSG: true,
          message: 'You have updated the status successfully.',
          errorType: 200,
        })
      }).catch(error=>console.log(error));
  }

  handleCloseDisplayMSG = () => {
    this.setState(prevState =>({
      displayMSG: !prevState.displayMSG
    }))
  }
  
  render() {
    let { classes } = this.props;
    let { loader, userLists, displayMSG, message, errorType } = this.state;
    return (           
        <Container className={classes.container}>
          {
            displayMSG ?            
              <Snackbar open={displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                <MuiAlert
                  onClose={this.handleCloseDisplayMSG}
                  severity={errorType === 200 ? 'success' : 'warning'}
                  elevation={6}
                  variant="filled"
                >
                  {message}
                </MuiAlert>                  
              </Snackbar>
              :
              null
          }
          <Card className={classes.root} variant="outlined">
          <CardContent>       
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                {
                  !loader ? (
                    <MaterialTable
                      title= {<Button color="primary" variant="outlined" size="large" startIcon={<AddIcon />} onClick={()=>this.PageLink()}>
                              ADD USER
                            </Button>}
                      columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Company', field: 'company' },
                        { title: 'Email', field: 'email' },
                        { title: 'Status', field: 'status' },
                        { title: 'Created Date', field: 'date' }
                      ]}
                      data={userLists}
                      options={{
                        draggable: false,
                        search: true,
                        sorting: true,
                        pageSize: 10,
                        paginationType: 'stepped',
                        showTitle: true,
                        toolbar: true,
                        maxBodyHeight: 500,
                        actionsColumnIndex: -1,
                        selection: true,
                      }}
                      actions={this.actions}
                      components={{
                        Container: props => props.children,
                        Toolbar: props => (
                          <MTableToolbar {...props} classes={{ root: classes.titleHeader }} />
                        )
                      }}
                    />
                  ) : (
                      <LinearProgress />
                  )}
              </Grid>
            </Grid>
            </CardContent>
          </Card>
        </Container>  
    );
  }
}
export default withStyles(styles)(withRouter(Users));