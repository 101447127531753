import React from 'react';
import { Drawer, Grid, Typography, withStyles, IconButton, CircularProgress, Snackbar } from '@material-ui/core';
import { Close, Add, Edit, Delete } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import ReactHtmlParser from 'react-html-parser';
import MuiAlert from "@material-ui/lab/Alert";
import { getQuestionListFromTest, removeQuestions } from '../../Method.util';

const styles = (theme) => ({
  drawer: {
    padding: '10px 15px',
    width: 800
  },
  marginTop: {
    paddingBottom: 20
  }
});

const tableRef = React.createRef();

class ManageQuestionDrawer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loader: true,
      questionList: [],
      displayMSG: false,
      errorType: null,
      message: ''
    };
  }

  componentDidMount() {
    this.getTestQuestions();
  }

  handleClose = () => {
    this.setState( prevState => ({
      open: !prevState.open
    }), () => {
      this.props.handleCloseDrawer();
    })
  }

  handleCloseDisplayMSG = () => {
    this.setState( prevState => ({
      displayMSG: !prevState.displayMSG
    }))
  }

  actions = [    
    {
      icon: () => <Delete color="error" />,
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        this.removeQuestionByID(rowData);
      },
      position: "row"
    }
  ];

  removeQuestionByID = async (rowData) => {
    this.setState({
      loader: true
    });
    const params = {
      testID: this.props.selectedTestID,
      questionID: rowData.id,
      token: this.props.userdata.token
    }

    const result = await removeQuestions(params);
    if(result.success) {
      this.setState({
        displayMSG: true,
        message: result.message,
        errorType: 200,
      })
      setTimeout(() => {
        this.getTestQuestions();          
      }, 500)
    }
  }

  getTestQuestions = async () => {
    this.setState({
      questionList: []
    });
    const params = {
      id: this.props.selectedTestID
    }
    const result = await getQuestionListFromTest(params);
    const questions = result.data;    
    if(questions && questions.length > 0) {
      this.setState({
        questionList : questions.map(item => {
            return {
              id : item.id,
              question : ReactHtmlParser(item.question)
            }
        })
      })
    }
    this.setState({
      loader: false
    })
  }

  render() {    
    let { classes, selectedTestName } = this.props;
    let { loader, questionList, displayMSG, errorType, message, questions } = this.state;
    return (
        <Drawer
          anchor='right'
          open={this.props.open}
          onClose={this.handleClose}
        >
          <Grid container className={classes.drawer}>
            {
              displayMSG ?          
                <Snackbar open={displayMSG} onClose={this.handleCloseDisplayMSG} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                           
                  <MuiAlert
                    onClose={this.handleCloseDisplayMSG}
                    severity={errorType == 200 ? 'success' : 'warning'}
                    elevation={6}
                    variant="filled"
                  >  
                    {message}
                  </MuiAlert>
                </Snackbar>
                :
                null
            }        
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                className={classes.marginTop}
              >
                <Grid item xs={9}>
                  <Typography variant="h6">Manage Questions in {selectedTestName}</Typography>
                  <Typography variant="p">Total Available Questions {questionList.length}</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <IconButton>
                    <Close onClick={this.handleClose} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{display: 'flow-root'}}
              >
                {
                  !loader ? (
                    <MaterialTable                      
                      columns={[
                        { title: 'ID', field: 'id', hidden: true },
                        { title: 'Question', field: 'question' }
                      ]}
                      data={questionList}
                      options={{
                        draggable: false,
                        search: false,
                        sorting: true,
                        pageSize: 20,
                        paginationType: 'stepped',
                        showTitle: false,
                        toolbar: false,
                        maxBodyHeight: 550,
                        actionsColumnIndex: -1,
                        selection: true,
                        rowStyle: {
                          fontSize: 12,
                        },
                        headerStyle: {
                          backgroundColor: '#efefef',
                          color: '#000'
                        },
                        selection: false
                      }}
                      actions={this.actions}
                      components={{
                        Container: props => props.children,
                        Toolbar: props => (
                          <MTableToolbar {...props} classes={{ root: classes.titleHeader }} />
                        )
                      }}
                    />
                  ) : (
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{marginTop: '50px'}}
                    >
                      <Grid item xs={3}>
                        <CircularProgress />
                      </Grid>          
                    </Grid>
                  )}
              </Grid>
          </Grid>
      </Drawer>
    );
  }
}
export default withStyles(styles)(ManageQuestionDrawer);