import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FaceIcon from '@material-ui/icons/Face';
import PersonIcon from '@material-ui/icons/Person';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FeedbackIcon from '@material-ui/icons/Feedback';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import { Colors } from '../../Common/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
    borderRadius : 10
  },
  secondaryTail: {
    backgroundColor: Colors.SECONDARY,
  },
  heading : {
      marginBottom : 10,
      display : 'inline-block',
      borderBottom : '4px solid ' + Colors.PRIMARY
  },
  subheading : {
      color : '#666',
      "@media (max-width: 767px)": {
            display: 'none'
        }
  },
  headingWrapper : {
    textAlign : 'center',
    margin : "30px 0"
  }
}));

export default function StepSection() {
  const classes = useStyles();

  return (
      <Container style={{paddingBottom: 30}}>
            <div className={classes.headingWrapper} style={{padding: '20px 0px'}}>
                <Typography variant="h4" style={{paddingBottom: 10}} className={classes.sectionHeading}>How It Works?</Typography>
                <Typography>We help candidates and job seekers to get hire by their dream companies.</Typography>
            </div> 
            <Timeline align="alternate">
            <TimelineItem>
                <TimelineSeparator>
                <TimelineDot>
                    <PersonIcon />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                <Paper elevation={5} className={classes.paper}>
                    <Typography variant="h5" component="h5" className={classes.heading}>
                        Setup an Account
                    </Typography>
                    <Typography className={classes.subheading}>Provide your information and registered email id. Verify it & you are ready to get started for a game changer journey. Login to start browsing our free mock assessments.
</Typography>
                </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineDot color="primary">
                    <FaceIcon />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                <Paper elevation={5} className={classes.paper}>
                    <Typography variant="h5" component="h5" className={classes.heading}>
                        Choose your Package
                    </Typography>
                    <Typography className={classes.subheading}>Want to have Predefined template for your test? Go through our Explore packages section to implement this and many more features depending upon the plan you select.
</Typography>
                </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                    <NoteAddIcon />
                </TimelineDot>
                <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={5} className={classes.paper}>
                        <Typography variant="h5" component="h5" className={classes.heading}>
                            Design your Assessment
                        </Typography>
                        <Typography className={classes.subheading}>Create your assessment or test series according to yourself. Choose different questions which match your company's difficulty level.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineDot color="secondary">
                    <FeedbackIcon />
                </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={5} className={classes.paper}>
                        <Typography variant="h5" component="h5" className={classes.heading}>
                            Get Performance Feedback
                        </Typography>
                        <Typography className={classes.subheading}>Get detailed feedback on your performance and areas you need to work upon.</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            </Timeline>
      </Container>
  );
}