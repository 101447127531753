import React, { Component } from 'react';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Colors } from '../../Common/colors';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    bannerWrapper : {
        textAlign : 'center',
        marginTop:60,
        padding: '50px 0px',     
        "@media (max-width: 767px)": {
            marginTop:60,
            padding: '25px 0px',
        },
        background: `url(${"./banner-bg.png"})`
    },
    bannerBackground : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        zIndex : 1      
    },
    bannerHeading : {
        fontSize : 48,
        "@media (max-width: 767px)": {
            fontSize : 36
        }
    },
    packagesButton : {
        padding : "7px 10px",
        fontSize : 14,
        marginTop : 10,
        marginRight : 20,
        background : Colors.PRIMARY,
        color : "#fff",
        "&:hover" : {
            background : 'rgb(5, 100, 100)',
        },
        "@media (max-width: 767px)": {
            fontSize : 12,
            marginRight : 10,
        }
    },
    marginBottom: {
        marginBottom: 20
    },
    marginTopBottom: {
        margin: '20px 0px'
    },
    imageSection: {
        "@media (max-width: 767px)": {
            display : 'none'
        }
    }
});

class Banner extends Component{

    redirectOnPage = (page) => {
        this.props.history.push(page);
    }

    render(){
        const { classes } = this.props;
        return (
            <div className={classes.bannerWrapper}>
                <div className={classes.bannerBackground}>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} sm={8}>
                                <Typography
                                    variant="h1"
                                    className={classes.bannerHeading}
                                >
                                Preparing candidates for your organization?
                                </Typography>
                                <Typography className={classes.marginTopBottom}>
                                Assist candidates by designing online mock test series yourself and help them prepare in a better way. Create online tests by selecting questions which meet level of difficulty of your organization and are frequently asked to employees.
                                </Typography>                              
                                <Button
                                    onClick={() => this.redirectOnPage('/signup') }                 
                                    className={classes.packagesButton}
                                >
                                    Start practicing for free
                                </Button>
                                <Button
                                    onClick={() => this.redirectOnPage('/pricing') }                       
                                    className={classes.packagesButton}
                                >
                                    Explore Packages
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} className={classes.imageSection}>
                                <img src="./banner-right.png" width={`100%`} />
                            </Grid>                            
                            </Grid>
                    </Container>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(Banner));