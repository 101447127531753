import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Footer from '../Frontend/Footer/Footer';
import Navbar from '../Frontend/Navbar/Navbar';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import CancelIcon from '@material-ui/icons/Close';
import { Drawer, FormControlLabel, Grid, Radio, RadioGroup, IconButton } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { Alert } from '@material-ui/lab';
import { Colors } from '../Common/colors';

const styles = theme => ({
  bannerWrapper : {
    background: 'linear-gradient(to right, rgb(220,240,252, 90%), rgb(220,240,252, 15%))',
    padding : 50,
    "@media (max-width: 767px)": {
      padding : '30px 0px'
    },
    '& button': {
      "@media (max-width: 767px)": {
        fontSize: 12,
        marginBottom: 15
      }
    }
  },
  Paper: {
    width: '70%',
    margin: '0px auto',
    padding: 30,
    "@media (max-width: 767px)": {
      width: '80%'
    },
    '& h5': {
      "@media (max-width: 767px)": {
        fontSize: 20
      }
    }
  },
  failIcon: {
    fontSize: 84,
    color: "red",
    alignItems: 'center',
    width:"100%",
    marginBottom: '10px',
    "@media (max-width: 767px)": {
      fontSize: 62
    }
  },
  passIcon: {
    fontSize: 84,
    color: "green",
    alignItems: 'center',
    width:"100%",
    marginBottom: '10px',
    "@media (max-width: 767px)": {
      fontSize: 62
    }
  },
  selectedAnswerCorrect: {
    border: '1px solid #4caf50',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(30, 70, 32)',
    backgroundColor: 'rgb(237, 247, 237)',
    "@media (max-width: 767px)": {
      padding: '6px 0px'
    },
    '& .MuiTypography-body1': {
      "@media (max-width: 767px)": {
        fontSize: 14
      }
    }
  },
  drawerWidth: {
    width: 700,
    "@media (max-width: 767px)": {
      width: 380,
    },
    padding: 20
  },
  reviewContainer: {
    width: '100%',
    padding: '20px',
    marginTop: '20px',
    '& h2': {
      fontSize: 18,
      marginTop: '10px',
    }
  },
  selectedOptions: {
    "@media (max-width: 767px)": {
      marginTop: 10
    }
  },
  optionLabel: {
    '& .MuiTypography-body1': {
      "@media (max-width: 767px)": {
        fontSize: 14,
        marginBottom: 10
      }
    }
  },
  closeButton: {
    background: '#efefef',
    padding: 6
  }
});

class Result extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false,
      setOpen:false,
      resultData: props.location.state,
      visibility: ''
    }
  }

  componentDidMount() {    
    this.validateTestDataExists();
  }

  validateTestDataExists = () => {
    const { testdata, testScore } = this.props;
    if((testdata && testdata.id) || (testScore && testScore.score_id)) {
      this.setState({
        visibility: testdata.visibility
      });
    } else {
      this.props.history.push('/online-test');
    }
  }

  handleClickOpen = () => {
    this.setState({
      open:true,
    })
  };

  handleClose = () => {
    this.setState({
      open:false,
    })
  };

  tryAgain = () => {
    const { testdata } = this.props;
    const slug = testdata.slug;
    this.props.history.push(slug);
  }

  backToAllTests = () => {
    this.props.history.push('online-test');
  }

  urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    console.log('regex.test(URL)', regex.test(URL));  
    return regex.test(URL);
  };

  render() {    
    let { classes, testdata, testScore } = this.props;
    return <div>
              <Navbar />
              <div style={{height : 50}}></div>
              <div className={classes.bannerWrapper}>
              <Container>
                  <Paper className={classes.Paper} elevation={4}>
                    {
                      testScore && testScore.score_percentage > 59 ?
                      <>      
                      <CheckCircleOutlined className={classes.passIcon}/>
                      <div>
                        <Typography variant="h5" align="center" gutterBottom display="block" style={{ textTransform: "uppercase"}}>
                        congratulations!
                        </Typography>
                        <Typography variant="h5" align="center" gutterBottom>
                        You answered {testScore.score}/{testScore.total_questions} questions correct
                        </Typography>                        
                        <Typography variant="body1" align="center" gutterBottom style={{ paddingTop: "10px"}}>
                        You have successfully completed this test. If you want to download an certificate then you can click on down certificate button.
                        </Typography>
                      </div>
                      </>
                      :
                      <>
                      <CancelOutlined className={classes.failIcon}/>
                      <div>
                        <Typography variant="h5" align="center" gutterBottom display="block" style={{ textTransform: "uppercase"}}>
                        I'm sorry
                        </Typography>
                        <Typography variant="h5" align="center" gutterBottom>
                        You answered {testScore.score}/{testScore.total_questions} questions correct
                        </Typography>                        
                        <Typography variant="body1" align="center" gutterBottom style={{ paddingTop: "10px"}}>
                        You did not successfully completed the test. You must get at least 60% of the questions correct in the order to pass this.
                        </Typography>
                      </div>
                      </>
                    }
                    
                  <div style={{width:"100%", margin:"20px 0px"}} align="center">
                    <Drawer
                      anchor='right'
                      open={this.state.open}
                      onClose={this.handleClose}
                    >                  
                        <Grid container className={classes.drawerWidth}>
                          <Grid
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid item xs={9}>
                              <Typography variant="h5" style={{ textTransform: "uppercase"}}>{testdata.title}</Typography>
                            </Grid>
                            <Grid item xs={3} container justifyContent="flex-end">
                              <Typography><IconButton className={classes.closeButton} onClick={this.handleClose}><CancelIcon /></IconButton></Typography>
                            </Grid>
                          </Grid>
                          {
                            testScore.list && testScore.list.map((item) => {
                              return <>
                                <Paper elevation={3} className={classes.reviewContainer}>
                                  <Grid item container>
                                      <Grid item xs={12}>
                                        {ReactHtmlParser(item.question)}
                                      </Grid>
                                      <Grid item xs={12} className={classes.selectedOptions}>
                                        <RadioGroup aria-label="selected_answer" name="selected_answer" value={item.selected_answer}>
                                          {item.options.map((opt, index)=>(
                                            <div key={index+1}>       
                                              <FormControlLabel 
                                                className={(item.options[item.answer - 1] == opt) ? classes.selectedAnswerCorrect : classes.optionLabel} 
                                                value={index+1} 
                                                control={<Radio checked={item.selected_answer === index+1} />}
                                              />
                                              {this.urlPatternValidation(opt) ? (
                                                  <span style={{verticalAlign: 'middle'}}><img height="auto" src={opt} width="100px" style={{marginBottom: 7}} /></span>
                                                ) : (
                                                  <span>{opt}</span>
                                                )}
                                          </div>
                                          ))}
                                        </RadioGroup>
                                      </Grid>
                                  </Grid>
                                </Paper>
                              </>                       
                            })
                          }
                                              
                        </Grid>
                    </Drawer>
                    {
                      testdata.visibility === 'Public' && (
                        <>
                          <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
                            Review your test
                          </Button>
                          <Button variant="contained" color="secondary" onClick={this.tryAgain} style={{marginLeft:"10px"}}>
                            Try Again
                          </Button>
                          <Button variant="contained" onClick={this.backToAllTests} style={{marginLeft:"10px"}}>
                            All Tests
                          </Button>
                        </>
                    )}
                  </div>
                  </Paper>
              </Container>
              </div>
            <Footer />
          </div>;
  }
}
const mapStateToProps = (state) => {
  return {
    testdata:state.CurrentPaper.paper,
    testScore:state.CurrentTestScore.score
  }
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Result)));