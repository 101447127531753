import React, { Component } from 'react';
import { Container, Grid, Typography, Button, Snackbar, CircularProgress } from '@material-ui/core';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import TextField from '@material-ui/core/TextField';
import { Colors } from './Common/colors';
import { withStyles } from '@material-ui/styles';
import Meta from './Metas';
import { DOMAIN, DEFAULT_META } from './Constants';
import axios from 'axios';
import { API_URL } from './Constants';
import MuiAlert from "@material-ui/lab/Alert";

const styles = theme => ({
    bannerWrapper : {
        background : Colors.SECONDARY,
        padding : 30,
        minHeight: 350
    },
    headingWrapper : {
        textAlign : 'center',
        marginBottom : 30,
        marginTop : 30
    },
    formContainer : {
        maxWidth: 800,
        margin: '0px auto'
    },
    submitButton : {
       background: Colors.PRIMARY
    },
    heading: {
        fontSize: 36
    }
});

class Contact extends Component{
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            name:'',
            email: '',
            message: '',
            disabled: false,
            displayMSG: false,
            errorType: null,
            alertMessage: ''
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value,
        });
    };

    handleSubmit = () => {
        this.setState({
            disabled: true
        });
        const { name, email, phone, message } = this.state;
        const params = {
            name,   
            email,
            phone,
            message
          }
          axios({method:"POST",url:API_URL+"contact",data:params})                        
            .then(data => {
                this.setState({
                    disabled: false,
                    open: true,
                    alertMessage: 'Thank you!, Your message has been successfully sent. We will contact you very soon!',
                    errorType: 200,
                    displayMSG: true,
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            })
            .catch(error => {
                this.setState({
                    disabled: false,
                    alertMessage: error.response.data.message,
                    errorType: error.response.data.statusCode,
                    displayMSG: true,
                    open: true
                });
            });
    };

    handleClose = () => {
        this.setState({
          open: false
        });
    }   

    render(){
        const { classes } = this.props;
        const { name, email, phone, message, alertMessage, displayMSG, errorType, open, disabled } = this.state;
        return (
            <div>
                <Meta meta_title={DEFAULT_META.META_TITLE_CONTACT} meta_description={DEFAULT_META.META_DESC_CONTACT} url={DOMAIN + '/contact-us'} />
                <Navbar />
                <div style={{height : 50}}></div>
                <div className={classes.bannerWrapper}>
                    {
                        displayMSG && (
                        <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>                  
                            <MuiAlert
                                onClose={this.handleClose}
                                severity={errorType == 200 ? 'success' : 'error'}
                                elevation={6}
                                variant="filled"
                            >
                                { alertMessage }
                            </MuiAlert>
                        </Snackbar>
                    )}
                    <Container>
                        <div className={classes.headingWrapper}>
                            <div>
                                <Typography
                                    variant="h1"
                                    className={classes.heading}
                                    style={{paddingBottom: 10}}
                                >
                                    Contact us
                                </Typography>
                                <Typography>
                                    If you have any doubt or any need any clarification then please fill the form.
                                </Typography>
                            </div>
                        </div>
                        <Grid 
                            container
                            spacing={2}
                            className={classes.formContainer}
                        >
                                <Grid item xs={4}>
                                    <TextField
                                        autoComplete="fname"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        value={name}
                                        label="Name"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        fullWidth
                                        value={email}
                                        label="Email"
                                        onChange={this.handleChange}     
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        name="phone"
                                        variant="outlined"
                                        fullWidth
                                        value={phone}
                                        label="Phone"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="message"
                                        name="message"
                                        variant="outlined"
                                        fullWidth
                                        value={message}
                                        multiline
                                        rows={4}
                                        label="Message"
                                        onChange={this.handleChange}    
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.submitButton}
                                        onClick={this.handleSubmit}
                                        disabled={disabled}
                                        >
                                            
                                        {
                                            disabled ?
                                            <CircularProgress size={27} />
                                            :
                                            <span>Submit your query</span>
                                        }
                                    </Button>
                                </Grid>
                        </Grid>
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}
export default withStyles(styles)(Contact);