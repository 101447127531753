import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid, Paper, TextField, Typography, Button, CircularProgress, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { updateProfile } from '../Method.util';
import DisplayMSGComp from './Common/DisplayMSG';
import {connect} from 'react-redux';
import { User } from '../Store/actions/Actions';

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {    
    overflow: 'auto',
    width: '100%'
  },
  container: {
    padding: theme.spacing(1),
  },
  seprateBox: {
    padding: 20,
    width: 400
  },
  spacing: {
    padding: '20px 0px'
  },
  submitButton: {
    marginTop: 15,
    marginRight: 20
  }
});

class EditProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.userdata.name,
      email: props.userdata.email,
      phone: props.userdata.phone,
      loader: false,
      displayMSG: false,
      errorType: null
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  returnOnProfile = () => {
    this.props.history.push('dashboard/profile');
  }

  handleSubmit = () => {
    this.setState({
      loader: true
    }, async () => {
      const { name, email, phone } = this.state;
      const params = {
        id: this.props.userdata.user_id,
        name: name,
        email: email,
        phone: phone,
        token: this.props.userdata.token,
      }
      const result = await updateProfile(params);
      if(result.success) {
        const userData = {...this.props.userdata, name, phone};
        this.props.dispatch(User(userData));
        localStorage.setItem("userData", JSON.stringify(userData));
        this.setState({
          displayMSG: true,
          loader: false,
          message: result.message,
          errorType: 200
        })
      } else {
        this.setState({
          displayMSG: true,
          loader: false,
          message: result.message,
          errorType: 419
        })
      }
    });
  }
  
  handleCloseDisplayMSG = () => {
    this.setState( prevState => ({
      displayMSG: !prevState.displayMSG
    }))
  }

  render() {
    let { classes } = this.props;
    const { name, email, phone, loader, displayMSG, errorType, message } = this.state;
      return (                  
          <Container className={classes.container}>
            <Typography variant="h5" component="div" className={classes.spacing}>
              Edit Profile
            </Typography>
            <DisplayMSGComp
              display={displayMSG}
              message={message}
              errorType={errorType}
              handleCloseDisplayMSG={this.handleCloseDisplayMSG}
            />
            <Card style={{paddingTop: 5, paddingLeft: 5}} variant="outlined">
              <CardContent>            
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >                  
                  <Paper elevation={3} className={classes.seprateBox}>                    
                      <TextField
                        margin="dense"     
                        label="Name"
                        fullWidth
                        name="name"
                        value={name}
                        required
                        variant="standard"
                        onChange={this.handleChange}
                      />
                      <TextField
                        margin="dense"     
                        label="Email Address"
                        fullWidth
                        name="email"
                        value={email}
                        required
                        variant="standard"
                        disabled="true"
                        onChange={this.handleChange}
                      />
                      <TextField
                        margin="dense"     
                        label="Phone"
                        fullWidth
                        name="phone"
                        value={phone}
                        required
                        variant="standard"
                        onChange={this.handleChange}
                      />
                      <Button 
                        variant="outlined"
                        color="primary"
                        className={classes.submitButton}
                        onClick={this.returnOnProfile}
                      >Back</Button>

                      <Button                            
                        onClick={this.handleSubmit}
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}
                      >
                        {
                          loader ?                              
                            <CircularProgress color="white" size={25}/>                              
                          :
                          <span>Update Profile</span>
                        }
                      </Button>                      
                  </Paper>
                </Grid>
              </CardContent>
            </Card>
          </Container>  
      );
    }
}

export default connect()(withStyles(styles)(withRouter(EditProfile)));