import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, TextField, Grid, Card, CardContent, Button, Typography } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  }
});

class Edit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {     
      
    };
  }

  componentDidMount() {
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value,
    });
  };

  render() {
    let { classes } = this.props;
    let { name, email } = this.state;
    
    return (
          <Container className={classes.container}>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>  
                    <Typography gutterBottom variant="h6" component="div">
                      Edit User
                    </Typography>
                  </Grid>                  
                </Grid>              
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>                    
                      <Grid item xs={6} sm={6}>               
                        <TextField
                          name="name"
                          variant="outlined"
                          fullWidth
                          label="Name"
                          value={name}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>     
                        <TextField
                          name="email"
                          variant="outlined"
                          fullWidth
                          label="Email"
                          value={email}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>                        
                        <Button variant="outlined" color="primary" style={{marginRight: 10}}>Back</Button>
                        <Button variant="contained" color="primary" onClick={this.handleSubmit}>Update</Button>
                      </Grid> 
                  </Grid>
                </form>
              </CardContent>        
            </Card>
          </Container>
    );
  }
}
export default withStyles(styles)(Edit);