import React, { Component } from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Container, Dialog, Grid, CardHeader, Typography, withStyles } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, API_URL } from '../../Constants';
import { Avatar } from '@mui/material';
import { Colors } from '../../Common/colors';

const styles = theme => ({
    testSection : {
        textAlign : 'center',
        padding : '50px 0',
        background: 'linear-gradient(to right, rgb(220,240,252, 60%), rgb(220,240,252, 5%))',
        "@media (max-width: 767px)": {
            padding : '30px 0px'
        }
    },
    headingContainer: {
        padding: '10px 0px 30px 0px',
        '& .MuiTypography-h1': {
            fontSize: 48,
            padding: '0px 0px 10px 0px',
            "@media (max-width: 767px)": {
                fontSize: 36,
                paddingBottom:20
            }
        },
        '& .MuiTypography-h2': {
            fontSize: 36,
            padding: '0px 0px 10px 0px',
            "@media (max-width: 767px)": {
                fontSize: 36
            }
        }
    },
    root : {
        textAlign : 'center',
        minHeight : 200,
        marginTop : 50,
        boxShadow : '0px 0px 5px #999',
        fontFamily : 'poppinsmedium'
    },
    cardHeader : {
        backgroundColor : '#088F8F',
        color: '#fff',       
        '& .MuiCardHeader-title' : {
            fontSize: 18,
        }
    },
    
    startTestButton : {
        border : '1px solid ' + Colors.PRIMARY,
        color : Colors.PRIMARY
    },
    
    cardContent : {
        minHeight : 130
    },
    courseName : {
        fontFamily : 'poppinsmedium',
        fontSize : 20,
        width : '100%',
        marginBottom: 10
    },
    subCourse : {
        fontFamily : 'poppinsmedium',
        fontSize : 14,
        width : '100%',
        color: '#666'
    },
    cardAction : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        height : 50
    },
    headingBold: {
        fontWeight: 500,
        textTransform: 'uppercase'
    }
});

class TestSection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          tests: [],
          open: false,
          loader: true
        };      
    }
    componentDidMount() {
        this.getAllTests();
    }

    getAllTests=()=>{
        axios({method:"POST",url:API_URL+"get-testlist-front", data:{isHome: this.props.isHome ? true : false}})                                 
          .then(data => {
            this.setState({   
              tests : data.data.data.map(item=>{
                  return {
                    id : item.id,
                    title : item.title,
                    display_title : item.display_title,
                    slug : item.slug,
                    rating : item.rating,
                    description: item.description,
                    logo: item.logo,
                    total_questions: item.total_questions
                  }
              }),
              loader: false
            })                                  
          }).catch(error=>console.log(error));
      }

      startTest = (slug) => {
        this.setState({
          open: true
        });
        setTimeout(() => {
            this.setState({
              open: false
            }, () => {
              this.props.history.push('/'+slug); 
            });
        }, 1000)  
    }

    render(){
        const { classes, title, description, container } = this.props;
        const { tests, loader } = this.state;
        return (
            <div className={classes.testSection}>
                <Container>
                    <div className={classes.headingContainer}>
                        <Typography variant={container}>{title}</Typography>
                        <Typography>{description}</Typography>
                    </div>
                    { loader ? (
                        <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{margin: '50px auto'}}
                      >
                        <Grid item xs={3}>
                        <CircularProgress />
                        </Grid>          
                      </Grid>
                        ) : (
                            <Grid container spacing={4}>
                            {
                                tests.map(item=> {
                                    return (
                                        <Grid item xs={12} md={3} sm={3} key={item.id}>
                                            <Card className={classes.root}>
                                                <CardHeader
                                                    className={classes.cardHeader}
                                                    title={item.display_title ? item.display_title : item.title}
                                                >
                                                </CardHeader>
                                                
                                                <CardContent className={classes.cardContent}>                                                    
                                                    <div className={classes.userInfo}>                                      
                                                        <Typography variant="body2" color="textSecondary" className={classes.subCourse}>
                                                            {item.description.substring(0, 110)}...
                                                        </Typography>
                                                        <Typography style={{paddingTop: 30}} className={classes.subCourse}>
                                                           <span class={classes.headingBold}>Questions : </span> {item.total_questions}  |  <span class={classes.headingBold}>Time : </span> {item.total_questions} minutes
                                                        </Typography>                                     
                                                    </div>
                                                </CardContent>
                                                <CardActions className={classes.cardAction}>                                                
                                                    <Grid item sm={5}>
                                                        <Button style={{width: 80}} size="small" color="primary"variant="outlined" onClick={() => this.startTest(item.slug)}>START</Button>
                                                    </Grid>
                                                    <Grid item sm={7}><Rating name="read-only" value={item.rating} precision={0.5} readOnly /></Grid> 
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )}
                    
                    <Dialog
                        open={this.state.open}
                        fullWidth
                        maxWidth="xs"
                    >
                        <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{margin: '30px auto'}}
                        >                    
                            <CircularProgress />
                            <Typography variant="h6" component="h6" className={classes.loadingContent}>
                                loading your paper
                            </Typography>
                        </Grid>
                    </Dialog>
                </Container>
            </div>
        )
    }
}

export default connect()(withStyles(styles)(withRouter(TestSection)));