import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import Footer from './Frontend/Footer/Footer';
import Navbar from './Frontend/Navbar/Navbar';
import { Colors } from './Common/colors';
import { withStyles } from '@material-ui/styles';
import Meta from './Metas';
import { DOMAIN, DEFAULT_META } from './Constants';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const styles = theme => ({
    bannerWrapper : {
        padding : 30,
        minHeight: 350
    },
    headingWrapper : {
        textAlign : 'center',
        marginBottom : 30,
        marginTop : 30
    },    
    submitButton : {        
       background: Colors.PRIMARY
    },
    heading: {
        fontSize: 36
    },
    li: {
        padding: '0px !important'
    },
    iconWidth: {
        minWidth: '32px !important'
    }
});

class PrivacyPolicy extends Component{
    render(){
        const { classes } = this.props;
        return (
            <div>
                <Meta meta_title="Privacy Policy - Online Mock Interviews" meta_description="This notice describes how your following technical information may be used or disclosed and how you can gain access to this information." url={DOMAIN + '/contact-us'} />
                <Navbar />
                <div style={{height : 50}}></div>
                <div className={classes.bannerWrapper}>
                    <Container>
                        <div className={classes.headingWrapper}>
                            <div>
                                <Typography variant="h1" className={classes.heading} style={{paddingBottom: 10}}>
                                    Privacy Policy
                                </Typography>
                                <Typography>
                                Welcome to one of the top e-learning platforms and thank you for becoming a part of our OnlineMockInterview family.
                                </Typography>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="p">At OnlineMockInterview, we totally understand the importance of privacy policy and that is why we have framed it so that you know how we collect personal information, use it, and disseminate your data.
Please note that this policy is applicable each time you visit our website. We hope it's clear that once you agreed with us and start using our services, you understand our privacy policy. Kindly don’t get enrolled if you don’t wish the same.
</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">How do you get my consent?</Typography>
                                <Typography variant="p">Your decision to provide Personal Information to OnlineMockInterview is totally your sole discretion and is received when you register or sign up on our website. In case you have not provided personal data you may not be able to access some offers, services, and options. You can sign up and can ask us for newsletter communication anytime. If in case you would like to unsubscribe it you can contact us or write a mail to us.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Data collected by us</Typography>
                                <Typography variant="p">
                                    <List>                                    
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="At the time of signup, we will collect certain data from the user which is directly entered by them on our online platform such as their personal information, academic information, and information regarding the plans you opt for. Such information will include various forms of it."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Apart from your personal information, we will also collect certain data automatically. This can be collected through server log files, tracking technologies such as Cookies, Data Collection Tools, etc. Such information includes Data Usage like total time spent on our website, number of times you have visited our website, etc"
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Data from your queries or reviews, post answers, interaction with any faculty is also collected by us."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Data from your assignments, tests which you have attempted shall also be recorded by us."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="In case of purchasing any plans online, payment can be done via net banking. To maintain your security we will not store any sensitive data."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="Data related to any issues or queries raised by you can also be stored in order to resolve that query."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="In order to improve our services and to ensure that OnlineMockInterview is updated with ever-changing demands of the students, we will organize surveys and promotional contests. For that, we may ask you to participate and store the data provided by the particular candidate."
                                            />
                                        </ListItem>
                                    </List>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Data Security</Typography>
                                <Typography variant="p">To protect your personal information, we take proper precautions and follow best practices of the industry to make sure it is not misused, get accessed by another user, disclosed, altered, or destroyed.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Age of Consent</Typography>
                                <Typography variant="p">By accessing this site, you are telling that you are fulfilling the age criteria and you have given your consent to allow any of your dependents to access this site.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Third-Party Ad Networks</Typography>
                                <Typography variant="p">Our website may use third-party advertisers to display advertisements about our products, based on your visits to our website. This allows third parties and us to show advertisements by publishing ads for our products which might interest you.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">To whom your data may disclose</Typography>
                                <Typography variant="p">
                                    <List>                                    
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="The information provided by you may disclose to our agents, representatives, consultants, and third-party providers who require it for the purpose of data storage, processing, etc."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="In case our company is acquired or merged with another one, your information may be transferred to the new owners in order to continue selling our plans, service to you."
                                            />
                                        </ListItem>
                                        <ListItem className={classes.li}>
                                            <ListItemIcon className={classes.iconWidth}>
                                                <ArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary="We may provide your information to our hiring partners at your consent only. We provide resumes, email addresses, phone numbers, and other relevant information to get you various job offers."
                                            />
                                        </ListItem>
                                    </List>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Changes to Privacy Policy</Typography>
                                <Typography variant="p">We have this right to modify our privacy policy at any time and therefore request you to check this page frequently. Changes and clarifications that are made will take effect once they are posted on the website. If there is any huge change made to this policy we will notify you about that updated section so that you are aware of it.
</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Questions</Typography>
                                <Typography variant="p">If you have any concerns, questions, issues regarding our policy privacy, or any other services of OnlineMockInterview you can contact us or write to <b>info@mockinterviewquestions.com</b>. In case you want to delete, edit or update any of your personal information we have about you raise a concern and we assure to resolve that in the next 24 hours.
</Typography>
                            </Grid>
                        </Grid>
                        <div style={{height : 50}}></div>
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default withStyles(styles)(PrivacyPolicy);