import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Routes from './route';
import { HelmetProvider } from 'react-helmet-async';
import { DEFAULT_META, DOMAIN } from './Component/Constants';
import Meta from './Component/Metas';

const theme = createTheme({
    overrides : {
      MuiButton : {
        root : {
          textTransform : 'capitalize',
          fontFamily : 'poppinsmedium',
        }
      },
      MuiTypography : {
        h1 : {
          fontFamily : 'poppinsmedium'
        },
        h2 : {
          fontFamily : 'poppinsmedium'
        },
        h3 : {
          fontFamily : 'poppinsmedium'
        },
        h4 : {
          fontFamily : 'poppinsmedium'
        },
        h5 : {
          fontFamily : 'poppinsmedium'
        },
        h6 : {
          fontFamily : 'poppinsmedium'
        },
        body1 : {
          fontFamily : 'poppinsmedium'
        }
      },
      MuiTextField : {
        root : {
          fontFamily : 'poppinsmedium',
          '&::placeholder' : {
            fontFamily : 'poppinsmedium'
          }
        }
      },
      MuiSnackbar : {
        root : {
          boxShadow : '2px 3px 10px #999',
          borderRadius : 5,
          fontFamily : 'poppinsmedium'
        }
      }
    }
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                <Meta meta_title={DEFAULT_META.META_TITLE} meta_description={DEFAULT_META.META_DESC} url={DOMAIN} />
              <Routes />
            </HelmetProvider>
        </ThemeProvider>
    )
}

export default App;