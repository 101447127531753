import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import ReactHtmlParser from 'react-html-parser';
import { Container, FormControlLabel, Grid, LinearProgress, RadioGroup, Snackbar } from '@material-ui/core';
import axios from 'axios';
import { API_URL, BASIC_TIMER_MINT } from '../Constants';
import { CurrentTestScore } from '../Store/actions/Actions';
import {connect} from 'react-redux';
import MuiAlert from "@material-ui/lab/Alert";

const styles = theme => ({
  Container: {
    width: '70%',
    textAlign: 'center',
    display: 'block',
    margin: '0px auto 30px auto',
    '& .MuiTypography-h1': {
      fontSize: 36,
      marginBottom: 10
    }
  },
  Paper: {
    width: '70%',
    margin: '0px auto',
    padding: 25,
    "@media (max-width: 767px)": {
      width: '80%',
      '& h6': {
        fontSize: 14
      },
      '& h2': {
        fontSize: 18
      },
      '& button': {
        "@media (max-width: 767px)": {
          fontSize: 12
        }
      }
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '0px'
    }
  },
  optionLabel: {
    '& .MuiTypography-body1': {
      "@media (max-width: 767px)": {
        fontSize: 14
      }
    }
    
  },
  previousButton: {
    float: "right",
    marginRight: "20px",
    "@media (max-width: 767px)": {
      float: "right",
      marginRight: "10px",
    }
  },
  questionArea: {
    borderTop: '3px solid #999',
    marginTop: 10,
    marginBottom: 20,
    '& code': {
      background: 'black',
      color: 'white',
      padding: 10,
      width: '70%',
      display: 'block',
      marginBottom: '-16px'
    },
    '& figure': {
      margin: 0,
      '& img': {
        "@media (max-width: 767px)": {
          maxWidth: '100%'
        }
      }      
    },
    '& h2': {      
      marginTop: '10px'
    },
  },
  optionSpacing: {
    marginTop: 5,
    "@media (max-width: 767px)": {
      marginTop: 12
    },
    '& .MuiRadio-colorSecondary': {
      padding: 5
    }
  },
  footer: {
    marginTop: 10,
    borderTop: '3px solid #999',
    paddingTop: 20
  },
  timerWidth: {
    width: 50,
    display: 'inline-block'
  },
  categoryName: {
    fontSize: 14,
    fontStyle: 'italic',
    color: '#777',
    textAlign: 'right',
    display: 'block',
    fontWeight: 500
  }
});

class PaperSheet extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        current: 0,
        quiz: props.quiz,
        selectedValue: '0',
        revealed: false,
        isLoader: false,
        isNextButtonDisabled: true,
        isSubmitButtonDisabled: true,
        seconds: 0,
        minutes: this.props.testdata.time > 0 ? this.props.testdata.time : BASIC_TIMER_MINT,
        visible: false,
        isPrevButtonDisabled: false,
        open: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.myInterval = setInterval(() => {
        const { seconds, minutes } = this.state

        if (seconds > 0) {
            this.setState(({ seconds }) => ({
                seconds: seconds - 1
            }))
        }
        if (seconds === 0) {
          if (minutes === 0) {
              this.setState({
                isSubmitButtonDisabled : false,
                isNextButtonDisabled: true,
                isPrevButtonDisabled: true,
                open: false
              }, () => {
                clearInterval(this.myInterval)
              });              
          } else {
              this.setState(({ minutes }) => ({
                  minutes: minutes - 1,
                  seconds: 59
              }))
              if(this.state.minutes < 1) {
                this.setState({
                  open: true
                }); 
              }
          }
        } 
    }, 1000);
}

componentWillUnmount() {
    clearInterval(this.myInterval)
}

  handleChange = (event,  question) => {
    if(this.state.seconds > 0 || this.state.minutes > 0) {
      this.updateItem(question.id, {selected_answer: Number(event.target.value)});
      this.setState({
        isNextButtonDisabled: false
      }, () => {
        this.disableSubmitButton();
      });
    }
  };

  updateItem = (id, itemAttributes) => {
    var index = this.state.quiz.findIndex(x=> x.id === id);
    if (index === -1) {
    } else {
      this.setState({
        quiz: [
           ...this.state.quiz.slice(0,index),
           Object.assign({}, this.state.quiz[index], itemAttributes),
           ...this.state.quiz.slice(index+1)
        ]
      });
    }
  }

  moveNext = () => {
    this.setState({      
      current: this.state.current+1
    }, () => { 
      let question = this.state.quiz[this.state.current];
      if(question.selected_answer) {
        this.setState({
          isNextButtonDisabled: false
        });
      } else {
        this.setState({
          isNextButtonDisabled: true
        });
      }
      this.disableSubmitButton();
    });
  }

  movePrevious = () => {
    this.setState({      
      current: this.state.current - 1
    }, () => { 
      let question = this.state.quiz[this.state.current];
      if(question.selected_answer) {
        this.setState({
          isNextButtonDisabled: false
        });
      } else {
        this.setState({
          isNextButtonDisabled: true
        });
      }
      this.disableSubmitButton();
    });
  }

  disableSubmitButton = () => {
    const { quiz } = this.state;
    const notAttemptQuestions = quiz.filter(function(element){
        return element.selected_answer == null;
    }).length
    if(notAttemptQuestions === 0) {
      this.setState({
        isSubmitButtonDisabled: false
      });
    }
  }

  submitForm = () => {
    this.setState({
      isLoader: true
    }, () => {
      const { quiz } = this.state;
      const { testdata, company_user_id } = this.props;
      axios({method:"POST",url:API_URL+"submit-paper", data:{test_id: testdata.id, company_id: testdata.company_id, company_user_id: company_user_id, com_response: quiz}})
        .then(data => {
          this.setState({
            isLoader: false
          },() => {    
            this.props.dispatch(CurrentTestScore(data.data.data));
            this.props.history.push('/result');
          })
        }).catch(error => {
          
        });
    });
  }

  urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    console.log('regex.test(URL)', regex.test(URL));  
    return regex.test(URL);
  };

  render(){
    var question = this.state.quiz[this.state.current];
    var curQuestion = this.state.current + 1;
    var size = this.state.quiz.length;
    var moveRight = this.state.current+1 < this.state.quiz.length;
    var moveLeft = this.state.current === 0;
    const { classes } = this.props;
    const { isNextButtonDisabled, isSubmitButtonDisabled, isPrevButtonDisabled, minutes, seconds } =  this.state;
  return (
    <Container>
      <Grid container className={classes.Container}>
        <Typography variant="h1">{this.props.testdata.title}</Typography>
        <Typography>{this.props.testdata.description.substring(0, 250)}...</Typography>
      </Grid>
      <Paper elevation={4} className={classes.Paper}>
        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>  
          <MuiAlert
            onClose={this.handleClose}
            severity="warning"
            elevation={6}
            variant="filled"
          >
            One minute left before quiz will be marked late!
          </MuiAlert>
          
        </Snackbar>
        <Grid container>
        {
          this.state.isLoader ?          
            <Grid item xs={12}>
              <LinearProgress />
            </Grid> 
          :
          <Grid item xs={12}>
            <Grid item container>
              <Grid item xs={4} md={4} sm={3}>
                <Typography component="h6" variant="h6">
                  <span> Question # {curQuestion} / {size}</span> 
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} sm={9} justify="flex-end" container>
                	<Typography component="h6" variant="h6">
                  {
                    this.props.testdata.marks > 0 && (
                      <span>Marks: {this.props.testdata.marks}</span>
                    )
                  }
                  <span style={{marginLeft: 50}}>Time: <span className={classes.timerWidth}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></span>
                  
                  </Typography>
              </Grid>
            </Grid>
            <div id="questionArea" className={classes.questionArea}>
              {
                question.category && (
                  <Typography variant="headline" className={classes.categoryName}><span style={{color: '#444'}}>Section: </span>{question.category}</Typography>
                )
              }              
              {ReactHtmlParser(question.question)}</div>
            <Typography variant="headline" component="h4" style={{marginTop: '20px'}}>
              Please choose your answer. 
            </Typography>
            <RadioGroup aria-label="selected_answer" name="selected_answer" value={question.selected_answer} onChange={(evt) => this.handleChange(evt, question)}>
            {question.options.map((opt, index)=>(
              <div key={index+1} className={classes.optionSpacing}>
                <FormControlLabel className={classes.optionLabel} value={index+1} control={<Radio checked={question.selected_answer === index+1} />} /> 
                {this.urlPatternValidation(opt) ? (
                  <span style={{verticalAlign: 'middle'}}><img height="auto" src={opt} width="150px" style={{marginBottom: 7}} /></span>
                ) : (
                  <span>{opt}</span>
                )}
            </div>
            ))}
            </RadioGroup>
            <div className={classes.footer}>
                <Button onClick={this.submitForm} variant="contained" color="secondary" disabled={isSubmitButtonDisabled}>
                  Submit
                </Button>
                {(moveRight)? (<Button onClick={this.moveNext} variant="contained" color="primary" style={{float: "right"}} disabled={isNextButtonDisabled}>
                  Next
                </Button>): (<Button onClick={this.moveNext} disabled variant="contained" color="primary" style={{float: "right"}}>
                  Next
                </Button>)}
                {(moveLeft)? ( <Button onClick={this.movePrevious} disabled variant="contained" color="primary" disabled={isPrevButtonDisabled} className={classes.previousButton}>
                  Previous
                </Button>): ( <Button onClick={this.movePrevious} variant="contained" color="primary" disabled={isPrevButtonDisabled} className={classes.previousButton}>
                  Previous
                </Button>)}
            </div>
          </Grid>
        }
        </Grid>     
      </Paper>
    </Container>
  );
  }
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect()(withStyles(styles)(PaperSheet));