import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withRouter } from 'react-router-dom';
import { Button, Container, List, ListItem, ListItemAvatar, Avatar, ListItemText, Hidden, Drawer, Grow } from '@material-ui/core';
import Image from '../../Assets/Images/ONline-mock-interviews.png';
import { Colors } from '../../Common/colors';
import { Link } from 'react-router-dom';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import QuizIcon from '@mui/icons-material/Quiz';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const styles = (theme) => ({
    navbar : {
        background : Colors.PRIMARY
    },
    secondaryButton : {
        background : Colors.SECONDARY,
        color : "#333"
    },
    grow: {
        flexGrow: 1,
      },
      head : {
        height : 100,
        background : Colors.PRIMARY,
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        color : '#fff'
      },
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
      paper : {
        width : '60%'
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      dflex : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        margin : 0,
        padding : 0
      },
      title: {
        display: 'block',
        cursor: 'pointer',
        paddingTop: 5,
        fontSize: 22,
        [theme.breakpoints.up('sm')]: {
          display: 'block',
          fontFamily : 'poppins'
        },
        },
        inputRoot: {
            color: 'inherit',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
            display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
            display: 'none',
            },
        },
        submenu : {
            display : 'block',
            opacity : 1,
            position : 'absolute',
            borderRadius : 5,
            boxShadow : '0px 0px 10px #999',
            transition : '0.5s',
            transform : 'translate(-50%,-50%)',
            background : '#fff',
            listStyle : "none",
            padding : 0,
            minWidth : 350,
            top : 60,
            left : -70,
            "& li" : {
                color : '#000',
                fontSize : 14,
                padding : "10px 20px",
                transition : '0.5s'
            },
            "&::before" : {
                content:'""',
                position: "absolute",
                right: '50%',
                marginLeft : 175,
                top: -10,
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "0 10px 10px 10px",
                borderColor: "transparent transparent #ffffff transparent",
                zIndex:9999
            },
        },
        li : {
            padding : "20px",
            listStyle : "none",
            fontFamily : 'poppins',
            cursor : 'pointer',
            position : 'relative',
            transition : '0.5s',
        },
        link : {
            display : 'flex',
            justifyContent : 'center',
            color : '#fff',
            textDecoration : 'none',
            alignItems : 'center',
        },
        primaryText : {
            fontFamily : 'poppinsmedium',
        },
        secondaryText : {
            fontFamily : 'poppinsmedium',
            fontSize : 14
        },
        AvatarRoot : {
            background : 'transparent',
            color : Colors.PRIMARY
        },
        listItemRoot : {
            '&:hover' : {
                background : Colors.SECONDARY
            }
        },
        web_link : {
            textDecoration : "none",
            display : 'flex',
        },
        link : {
            textDecoration : "none",
            display : 'flex',
            color : '#000'
        },
        nested : {
            marginLeft : 10
        }
});

const list = [
    {
        id : 'services',
        name : "Services",
        link : 'service',
        submenu : [
            {
                icon : <QuizIcon style={{fontSize:'36px'}} />,
                id : 'onlineTest',
                name : 'Online Test',
                link : 'online-test',
                subText : 'Want MCQ\'s click here'
            }
        ]
    },
    {
        id : 'onlinetest',
        name : 'Online Tests',
        link : 'online-test',
        submenu : []
    },
    {
        id : 'pricing',
        name : 'Pricing',
        link : 'pricing',
        submenu : []
    },
    {
        id : 'reactus',
        name : 'Reach us',
        submenu : [
            {
                icon : <LiveHelpIcon style={{fontSize:'36px'}} />,
                id : 'onlineTest',
                name : 'FAQ\'s',
                link : 'faq',
                subText : 'How can we help?'
            },            
            {
                icon : <ConnectWithoutContactIcon style={{fontSize:'36px'}} />,
                id : 'contactus',
                name : 'Contact us',
                link : 'contact-us',
                subText : 'Submit youy query'
            },
            {
                icon : <LiveHelpIcon style={{fontSize:'36px'}} />,
                id : 'onlineTest',
                name : 'How it works?',
                link : 'how-to-get-started',
                subText : 'How it works?'
            },
        ]
    }
]

const mobileMenuId = 'primary-search-account-menu-mobile';
class Navbar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobileMoreAnchorEl: null,
            anchor: false,
            open: false,
            isMobileMenuOpen: false,
            id : ""      
        }
      }

    handleMobileMenuClose = () => {
        this.setState({mobileMoreAnchorEl: null});
    };

    handleMobileMenuOpen = (event) => {
        this.setState({mobileMoreAnchorEl: event.currentTarget});
    };

    handleMenuOpen = () => {
        this.setState({anchor: true});
    }

    handleCloseAchor = () => {
        this.setState({anchor: false});
    }

    handleClick = (e) => {
        this.setState({open: !this.state.open, id : e.currentTarget.id});
    };

    movetoPage = (page) => {
        this.props.history.push(page);
    }
    handleMouseEnter = e => {
        this.setState({
            open : true, 
            id : e.currentTarget.id
        })
    }
    render(){
        const { classes } = this.props;
    return (
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.navbar}>
                <Container style={{padding: 0}}>
                    <Hidden smDown>
                        <Toolbar>                       
                            <img src={Image} style={{width : 160}} className={classes.title} alt="menu"  onClick={() => this.movetoPage('/')}/>
                            <div className={classes.grow} />
                            <ul className={classes.dflex}>
                                {
                                    list.map(item=> {
                                        return (
                                            <li 
                                                className={classes.li} 
                                                key={item.id} 
                                                id={item.id}
                                                onMouseEnter={item.submenu.length > 0 ? this.handleMouseEnter : null}
                                                onMouseLeave={item.submenu.length > 0 ? this.handleClick : null}
                                                >
                                                <a                                                   
                                                    className={classes.web_link}
                                                    onClick={() => this.movetoPage(item.link)}
                                                    >
                                                {
                                                    item.name
                                                }
                                                {
                                                    item.submenu.length > 0
                                                    && (
                                                        this.state.open && this.state.id === item.id
                                                        ?
                                                        <ExpandLess
                                                        />
                                                        :
                                                        <ExpandMore
                                                        />
                                                    )
                                                }
                                                </a>
                                                {
                                                    this.state.open && this.state.id === item.id
                                                    &&
                                                    <Grow in={this.state.open}>
                                                    <List className={classes.submenu} onMouseLeave={this.handleClick}>
                                                        {
                                                            item.submenu.map(item1=>{
                                                                return (
                                                                    <Link
                                                                        to={'/'+item1.link}
                                                                        key={item1.id}
                                                                        className={classes.link}
                                                                        >
                                                                        <ListItem 
                                                                            
                                                                            classes={{
                                                                                root : classes.listItemRoot
                                                                            }}
                                                                            >
                                                                            <ListItemAvatar>
                                                                            <Avatar
                                                                                classes={{
                                                                                    root : classes.AvatarRoot
                                                                                }}
                                                                            >
                                                                                {item1.icon}
                                                                            </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText 
                                                                                primary={item1.name} 
                                                                                secondary={item1.subText} 
                                                                                classes={{
                                                                                    primary : classes.primaryText,
                                                                                    secondary : classes.secondaryText
                                                                                }}
                                                                                />
                                                                        </ListItem>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                    </Grow>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div>
                                <Button
                                    variant="contained"
                                    className={
                                        classes.secondaryButton
                                    }
                                    style={{marginRight : 20}}
                                    onClick={() => this.movetoPage('login')}
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="contained"
                                    className={
                                        classes.secondaryButton
                                    }
                                    onClick={() => this.movetoPage('signup')}
                                    >
                                        Sign Up
                                </Button>
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                                color="inherit"
                                >
                                <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Hidden>
                    <Hidden mdUp>
                        <Toolbar>
                            <MenuIcon
                                onClick={this.handleMenuOpen}
                                style={{
                                    marginRight : 30,
                                    fontSize: 32
                                }}
                            />
                            <Typography className={classes.title} variant="h6" onClick={() => this.movetoPage('/')} noWrap>
                                Mock Interview Test
                            </Typography>
                            <Drawer 
                                anchor="left" 
                                open={this.state.anchor} 
                                onClose={this.handleCloseAchor}
                                classes={{
                                    paper : classes.paper
                                }}
                                >
                                <div className={classes.head}>
                                    <img src={Image} style={{width : 215}} className={classes.title} alt="menu" onClick={() => this.movetoPage('/')}/>
                                </div>
                                <List
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                    className={classes.root}
                                    >
                                    {
                                        list.map(item=> {
                                            return (
                                                <>
                                                    {
                                                        !item.submenu.length > 0
                                                        ?
                                                        (
                                                            <Link to={item.link} className={classes.link} key={item.link}>
                                                                <ListItem button onClick={item.submenu.length > 0 && this.handleClick} id={item.id}>
                                                                <ListItemText primary={item.name} />
                                                                {
                                                                    item.submenu.length > 0
                                                                    ?
                                                                    this.state.open && this.state.id === item.id ? <ExpandLess /> : <ExpandMore /> : null
                                                                }
                                                            </ListItem>
                                                            </Link>
                                                        )
                                                        :
                                                        <ListItem button onClick={item.submenu.length > 0 && this.handleClick} id={item.id} key={item.link}>
                                                            <ListItemText primary={item.name} />
                                                            {
                                                                item.submenu.length > 0
                                                                ?
                                                                this.state.open && this.state.id === item.id ? <ExpandLess /> : <ExpandMore /> : null
                                                            }
                                                        </ListItem>
                                                    }
                                                    {
                                                        item.submenu.map(item1=>{
                                                            return (
                                                                <Collapse in={this.state.open && this.state.id === item.id} timeout="auto" unmountOnExit>
                                                                    <List component="div" disablePadding>
                                                                        <Link to={item1.link} className={classes.link}>
                                                                            <ListItem button className={classes.nested}>
                                                                                <ListItemText primary={item1.name} />
                                                                            </ListItem>
                                                                        </Link>
                                                                    </List>
                                                                </Collapse>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </List>
                            </Drawer>
                        </Toolbar>
                    </Hidden>
                </Container>
            </AppBar>
        </div>
    );
}
}

export default withStyles(styles)(withRouter(Navbar));