import { combineReducers } from 'redux';
import User from './User';
import CurrentPaper from './CurrentPaper';
import CurrentTestScore from './CurrentTestScore';

const rootReducer = combineReducers({
    User,
    CurrentPaper,
    CurrentTestScore
})

export default rootReducer;