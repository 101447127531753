import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Navbar from '../Frontend/Navbar/Navbar';
import QuestionSection from '../Frontend/QuestionSection/QuestionSection';
import Footer from '../Frontend/Footer/Footer';
import axios from 'axios';
import { API_URL } from '../Constants';
import { withRouter } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Colors } from '../Common/colors';
import {Helmet} from "react-helmet-async";

const styles = (theme) => ({
  bannerWrapper : {
    background : Colors.SECONDARY,
    padding : 50,
    marginTop: '50px'
  },
  Paper: {
    maxWidth: '400px',
    margin: '0px auto',
    padding: '30px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: Colors.PRIMARY
  },
  form: {
    margin: '20px 0px',
    width: '100%',
    '& .MuiFormHelperText-contained': {
      margin: '0px !important'
    }
  },
  link: {
    cursor: 'pointer'
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      emailHelperText: '',
      displayMSG: false,
      message: '',
      isLoader: false
    }
  }

  handleChange = (event) => {
    const name = event.target.name;
    this.setState({
      [name]: event.target.value,
    });
  };

  handleLoginForm = () => {
    if(!this.state.email.includes("@")) {
      this.setState({
        emailError: true,
        emailHelperText: "Please enter a valid email",
        isLoader: false
      })
      return false;
    } else {
      this.setState({
        emailError: false,
        emailHelperText: "",
        isLoader: true
      })
    }
    const params = {
      email: this.state.email
    }
    axios({method:"POST",url:API_URL+"signin",data: params})                                 
      .then(data => {        
        const user_id = data.data.data.user_id;
        this.props.history.push({ pathname: '/verify', state: { user_id: user_id, type: '/login'}});    
      }).catch(error => {
        if(error && error.response) {
          this.setState({
            email: '',
            message: error.response.data.message,
            displayMSG: true,
            isLoader: false
          });
        }
      });
  }
  goToLink = (page) => {
    this.props.history.push(page);
  }
  render() {      
    let { classes } = this.props;
    const { email, emailError, emailHelperText, displayMSG, message, isLoader } = this.state;
    return <div>
      <Helmet>
          <title>Login - Online Mock Interviews</title>
          <meta name="description" content="Practice online mock tests for every competitive exam? Online Mock interview practice software that helps you land your ideal job by improving interview skills." />
          <link rel="canonical" href="https://www.onlinemockinterviews.com/login" />
      </Helmet>
      <Navbar />
    <div className={classes.bannerWrapper}>  
      <Container>
      <Paper elevation={4} className={classes.Paper}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Avatar>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component="h1" variant="h5">
                Login
              </Typography>
          </Grid>
          
            <form className={classes.form} noValidate>
              <Grid item container spacing={2}>
              {
                  displayMSG && (
                    <Grid item xs={12} sm={12}>          
                      <Alert severity="error" style={{marginBottom:"5px"}}>
                        <AlertTitle>Error: {message}</AlertTitle>                  
                      </Alert>
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="email"
                    value={email}
                    variant="outlined"              
                    fullWidth              
                    label="Enter your email"
                    error={emailError}
                    helperText={emailHelperText}
                    autoFocus
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleLoginForm}
                disabled={isLoader}
              >
                {
                  isLoader ?       
                  <CircularProgress color="white" size={27} />
                  :
                  <span>Login</span>
                }
              </Button>
              <Grid container>
                  <Grid item xs>
                    <Link onClick={() => this.goToLink('forgot-password')} className={classes.link}>
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link onClick={() => this.goToLink('signup')}>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
            </form>
          </Grid>
        </Paper>
      </Container>
    </div>
    <div style={{height : 50}}></div>
  <QuestionSection /><Footer /></div>;
  }
}

export default withStyles(styles)(withRouter(Login));