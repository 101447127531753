import React, { Component } from 'react';

class About extends Component{
constructor(props) {
    super(props);
    this.state = {
        list: [{name: "Ram"}, {name: "Geeta"}, {name: "Sita"}],
        list2: [{name: "Ram"}, {name: "Shyam"}, {name: "Seeta"}]
    };
}

componentDidMount() {
    this.update();
}

update = () => {
    const { list, list2 } = this.state;
    console.log([...list, ...list2].length);
}      

    render(){

        const { classes } = this.props;
        return (
            <div>
                Hello
            </div>
        )
    }
}

export default About;