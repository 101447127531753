import React from 'react';
import { Router,Switch,Route } from 'react-router-dom';
import history from './helper/history';
import PrivateRoute from './helper/privateRoute';
import UserSignup from './Component/Company/Signup';
import Pricing from './Component/Pricing';
import Contact from './Component/Contact';
import Faq from './Component/Faq';
import Home from './Component/Frontend/HomePage/Homepage';
import Dashboard from './Component/Dashboard/dashboard';
import Result from './Component/Test/Result';
import Login from './Component/Company/Login';
import ForgotPassword from './Component/Company/ForgotPassword';
import Verify from './Component/Company/Verify';
import Test from './Component/Test/Test';
import TestList from './Component/Test/List';
import GetStarted from './Component/GetStarted';
import About from './Component/About';
import Testing from './Component/Testing';
import PrivacyPolicy from './Component/PrivacyPolicy';

const Routes = () => (
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/signup" component={UserSignup} />                
                <Route path="/verify" component={Verify} />
                <Route path="/result" component ={Result} />
                <Route path="/contact-us" component ={Contact} />
                <Route path="/about" component ={About} />
                <Route path="/test" component ={Testing} />
                <Route path="/privacy-policy" component ={PrivacyPolicy} />
                <Route path="/faq" component ={Faq} />
                <Route path="/how-to-get-started" component ={GetStarted} />
                <Route path="/online-test" component ={TestList} />
                <Route path="/:slug" exact component ={Test} />

                <PrivateRoute path="/dashboard/:page_type/:param1?" component ={Dashboard} />    
            </Switch>
        </Router>
    )
export default Routes;